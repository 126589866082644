import React from 'react';
import Header from '../header/header';
import { Container, Row, Col } from 'react-bootstrap';
import './ScienceProgram.css'
import { Carousel } from '../carousel/carousel';
import { Part3 } from '../Part_3/Part_3';
import { useLocation } from 'react-router-dom';
import Aiml from '../ai-ml/ai-ml';
import Mapadd from '../map-add/map-add';
import Card from '../card-img/card-img';
import { StemMindsCourse } from '../StemMindsCourse/StemMindsCourse';
import AvishkaarProduct from '../AvishkaarProduct/AvishkaarProduct';
import ServiceSection from '../OurPlatform/ServiceDetails/serviceSection';
import { useMediaQuery } from 'react-responsive';
import Chatbot from '../Chatbot/chatbot';


function ScienceProgram() {
  const location = useLocation();
  console.log(location.pathname);

  const isSmall = useMediaQuery({ query: '(max-width: 576px)' });
  const isMedium = useMediaQuery({ query: '(min-width: 577px) and (max-width: 768px)' });
  const isLarge = useMediaQuery({ query: '(min-width: 769px) and (max-width: 992px)' });
  const isXLarge = useMediaQuery({ query: '(min-width: 993px) and (max-width: 1200px)' });
  const isXXLarge = useMediaQuery({ query: '(min-width: 1201px)' });

  return (
    <div>
      {/* <NewHeader /> */}
      <Carousel />
      <Header />

      <div>

        {isSmall && (
          <div className="container-sm" style={{ marginTop: "270px", marginBottom: "-250px" }}>
            <div>
              <div className='d-flex justify-content-center'>
                <img src="robotics1.png" alt="STEM Education" style={{ boxShadow: '3px 5px 6px gray', borderRadius: '15px' }} />
              </div>
              <div>
                <h1 className='text-center mt-1'>Robotics</h1>
                <p style={{ textAlign: "justify" }}>Robotics is an exciting field that combines engineering, computer science, and creativity, making it an ideal learning opportunity for K-12 students. By engaging in robotics, students learn to design and build robots while developing critical problem-solving skills and teamwork. Robotics fosters innovation by encouraging students to explore advanced technologies like artificial intelligence and automation, preparing them for future careers in STEM (Science, Technology, Engineering, and Mathematics). Through hands-on projects and competitions, students not only acquire technical skills but also gain confidence in tackling real-world challenges. Robotics education inspires curiosity, promotes collaboration, and empowers students to become the innovators and leaders of tomorrow.</p>
                <button type="button" className="btn btn-warning">View More</button>
                <button type="button" className="btn btn-danger ms-3">Just Watch</button>
              </div>
            </div>
          </div>
        )}

        {isMedium && (
          <div className="container-sm mb-2" style={{ marginTop: "250px" }}>
            <div>
              <div className='d-flex justify-content-center'>
                <img src="robotics1.png" alt="STEM Education" style={{ boxShadow: '3px 5px 6px gray', borderRadius: '15px' }} />
              </div>
              <div>
                <h1 className='text-center mt-1'>Robotics</h1>
                <p style={{ textAlign: "justify" }}>Robotics is an exciting field that combines engineering, computer science, and creativity, making it an ideal learning opportunity for K-12 students. By engaging in robotics, students learn to design and build robots while developing critical problem-solving skills and teamwork. Robotics fosters innovation by encouraging students to explore advanced technologies like artificial intelligence and automation, preparing them for future careers in STEM (Science, Technology, Engineering, and Mathematics). Through hands-on projects and competitions, students not only acquire technical skills but also gain confidence in tackling real-world challenges. Robotics education inspires curiosity, promotes collaboration, and empowers students to become the innovators and leaders of tomorrow.</p>
                <div>
                  <button type="button" className="btn btn-warning">View More</button>
                  <button type="button" className="btn btn-danger ms-3">Just Watch</button>
                </div>
              </div>
            </div>
          </div>
        )}

        {isLarge && (
          <div className="container-sm" style={{ marginTop: "250px" }}>
            <div>
              <div className='d-flex justify-content-center'>
                <img src="robotics1.png" alt="STEM Education" style={{ boxShadow: '3px 5px 6px gray', borderRadius: '15px' }} />
              </div>
              <div>
                <h1 className='text-center mt-1'>Robotics</h1>
                <p style={{ textAlign: "justify" }}>Robotics is an exciting field that combines engineering, computer science, and creativity, making it an ideal learning opportunity for K-12 students. By engaging in robotics, students learn to design and build robots while developing critical problem-solving skills and teamwork. Robotics fosters innovation by encouraging students to explore advanced technologies like artificial intelligence and automation, preparing them for future careers in STEM (Science, Technology, Engineering, and Mathematics). Through hands-on projects and competitions, students not only acquire technical skills but also gain confidence in tackling real-world challenges. Robotics education inspires curiosity, promotes collaboration, and empowers students to become the innovators and leaders of tomorrow.</p>
                <div>
                  <button type="button" className="btn btn-warning">View More</button>
                  <button type="button" className="btn btn-danger ms-3">Just Watch</button>
                </div>
              </div>
            </div>
          </div>
        )}

        {isXLarge && (
          <div className="container-xxl" style={{ marginTop: "250px" }}>
            <Container className="bg-gradient p-4">
              <Row className="align-items-center">
                <Col md={6}>
                  <img
                    src="robotics1.png"
                    alt="STEM Education"
                    className="img-content" style={{ boxShadow: '3px 5px 6px gray', borderRadius: '15px' }}
                  />
                </Col>
                <Col md={6} className="text-black p-4">
                  <h1 className="stmsize">Robotics</h1>
                  <span className='text-warning'>***************************</span>
                  <p className='text-about' style={{ textAlign: "justify" }}>
                    Robotics is an exciting field that combines engineering, computer science, and creativity, making it an ideal learning opportunity for K-12 students. By engaging in robotics, students learn to design and build robots while developing critical problem-solving skills and teamwork. Robotics fosters innovation by encouraging students to explore advanced technologies like artificial intelligence and automation, preparing them for future careers in STEM (Science, Technology, Engineering, and Mathematics). Through hands-on projects and competitions, students not only acquire technical skills but also gain confidence in tackling real-world challenges. Robotics education inspires curiosity, promotes collaboration, and empowers students to become the innovators and leaders of tomorrow.
                  </p>
                  <div>
                    <button type="button" className="btn btn-warning">View More</button>
                    <button type="button" className="btn btn-danger ms-3">Just Watch</button>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        )}


        {isXXLarge && (
          <div className="container-xxl">
            <Container className="bg-gradient p-4">
              <Row className="align-items-center">
                <Col md={6}>
                  <img
                    src="robotics1.png"
                    alt="STEM Education"
                    className="img-content" style={{ boxShadow: '3px 5px 6px gray', borderRadius: '15px' }}
                  />
                </Col>
                <Col md={6} className="text-black p-4">
                  <h1 className="mb-4 stmsize">Robotics</h1>
                  <span className='text-warning'>******************************</span>
                  <p className='text-about' style={{ textAlign: "justify" }}>
                    Robotics is an exciting field that combines engineering, computer science, and creativity, making it an ideal learning opportunity for K-12 students. By engaging in robotics, students learn to design and build robots while developing critical problem-solving skills and teamwork. Robotics fosters innovation by encouraging students to explore advanced technologies like artificial intelligence and automation, preparing them for future careers in STEM (Science, Technology, Engineering, and Mathematics). Through hands-on projects and competitions, students not only acquire technical skills but also gain confidence in tackling real-world challenges. Robotics education inspires curiosity, promotes collaboration, and empowers students to become the innovators and leaders of tomorrow.
                  </p>
                  <div>
                    <button type="button" className="btn btn-warning">View More</button>
                    <button type="button" className="btn btn-danger ms-3">Just Watch</button>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        )}

      </div>

      <Part3 />
      <ServiceSection />
      <Card />
      <StemMindsCourse />
      <Aiml />
      <AvishkaarProduct />
      <Mapadd />
      <Chatbot/>

      <div className="footer">
      </div>
    </div>
  );
}

export default ScienceProgram;