import React from 'react';
import Header from '../header/header';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Carousel } from '../carousel/carousel';
import { Part3 } from '../Part_3/Part_3';
import Aiml from '../ai-ml/ai-ml';
import Mapadd from '../map-add/map-add';
import Card from '../card-img/card-img';
import { StemMindsCourse } from '../StemMindsCourse/StemMindsCourse';
import AvishkaarProduct from '../AvishkaarProduct/AvishkaarProduct';
import ServiceSection from '../OurPlatform/ServiceDetails/serviceSection';
import { useMediaQuery } from 'react-responsive';
import ChatbotToggle from '../Chatbot/chatbot';

function Printing() {

  const isSmall = useMediaQuery({ query: '(max-width: 576px)' });
  const isMedium = useMediaQuery({ query: '(min-width: 577px) and (max-width: 768px)' });
  const isLarge = useMediaQuery({ query: '(min-width: 769px) and (max-width: 992px)' });
  const isXLarge = useMediaQuery({ query: '(min-width: 993px) and (max-width: 1200px)' });
  const isXXLarge = useMediaQuery({ query: '(min-width: 1201px)' });

  return (
    <div>
      {/* <NewHeader /> */}
      <Carousel />
      <Header />
      <div>

        {isSmall && (
          <div className="container-sm" style={{ marginTop: "270px", marginBottom: "-250px" }}>
            <div>
              <div className='d-flex justify-content-center'>
                <img src="3d-printer1.png" alt="STEM Education" style={{ boxShadow: '3px 5px 6px gray', borderRadius: '15px' }} />
              </div>
              <div>
                <h1 className='text-center mt-1'>Printing</h1>
                <p style={{ textAlign: "justify" }}>3D printing opens a world of creativity and innovation for K-12 students, allowing them to transform digital designs into tangible objects. This technology empowers students to explore their imaginations and bring ideas to life, whether they are creating prototypes, artistic sculptures, or functional tools. Through hands-on experience with 3D printing, students learn valuable skills in design, engineering, and problem-solving. It fosters collaboration as students work together to refine designs and troubleshoot printing challenges. 3D printing also introduces students to concepts of material science and manufacturing, preparing them for future careers in fields like engineering and product development. By embracing 3D printing, K-12 students gain a deeper understanding of technology and its potential to shape our world.</p>
                <button type="button" className="btn btn-warning">View More</button>
                <button type="button" className="btn btn-danger ms-3">Just Watch</button>
              </div>
            </div>
          </div>
        )}

        {isMedium && (
          <div className="container-sm mb-2" style={{ marginTop: "250px" }}>
            <div>
              <div className='d-flex justify-content-center'>
                <img src="3d-printer1.png" alt="STEM Education" style={{ boxShadow: '3px 5px 6px gray', borderRadius: '15px' }} />
              </div>
              <div>
                <h1 className='text-center mt-1'>Printing</h1>
                <p style={{ textAlign: "justify" }}>3D printing opens a world of creativity and innovation for K-12 students, allowing them to transform digital designs into tangible objects. This technology empowers students to explore their imaginations and bring ideas to life, whether they are creating prototypes, artistic sculptures, or functional tools. Through hands-on experience with 3D printing, students learn valuable skills in design, engineering, and problem-solving. It fosters collaboration as students work together to refine designs and troubleshoot printing challenges. 3D printing also introduces students to concepts of material science and manufacturing, preparing them for future careers in fields like engineering and product development. By embracing 3D printing, K-12 students gain a deeper understanding of technology and its potential to shape our world.</p>
                <div>
                  <button type="button" className="btn btn-warning">View More</button>
                  <button type="button" className="btn btn-danger ms-3">Just Watch</button>
                </div>
              </div>
            </div>
          </div>
        )}

        {isLarge && (
          <div className="container-sm" style={{ marginTop: "250px" }}>
            <div>
              <div className='d-flex justify-content-center'>
                <img src="3d-printer1.png" alt="STEM Education" style={{ boxShadow: '3px 5px 6px gray', borderRadius: '15px' }} />
              </div>
              <div>
                <h1 className='text-center mt-1'>Printing</h1>
                <p style={{ textAlign: "justify" }}>3D printing opens a world of creativity and innovation for K-12 students, allowing them to transform digital designs into tangible objects. This technology empowers students to explore their imaginations and bring ideas to life, whether they are creating prototypes, artistic sculptures, or functional tools. Through hands-on experience with 3D printing, students learn valuable skills in design, engineering, and problem-solving. It fosters collaboration as students work together to refine designs and troubleshoot printing challenges. 3D printing also introduces students to concepts of material science and manufacturing, preparing them for future careers in fields like engineering and product development. By embracing 3D printing, K-12 students gain a deeper understanding of technology and its potential to shape our world.</p>
                <div>
                  <button type="button" className="btn btn-warning">View More</button>
                  <button type="button" className="btn btn-danger ms-3">Just Watch</button>
                </div>
              </div>
            </div>
          </div>
        )}

        {isXLarge && (
          <div className="container-xxl" style={{ marginTop: "250px" }}>
            <Container className="bg-gradient p-4">
              <Row className="align-items-center">
                <Col md={6}>
                  <img
                    src="3d-printer1.png"
                    alt="STEM Education"
                    className="img-content" style={{ boxShadow: '3px 5px 6px gray', borderRadius: '15px' }}
                  />
                </Col>
                <Col md={6} className="text-black p-4">
                  <h1 className="stmsize">Printing</h1>
                  <span className='text-warning'>***********************</span>
                  <p className='text-about' style={{ textAlign: "justify" }}>
                    3D printing opens a world of creativity and innovation for K-12 students, allowing them to transform digital designs into tangible objects. This technology empowers students to explore their imaginations and bring ideas to life, whether they are creating prototypes, artistic sculptures, or functional tools. Through hands-on experience with 3D printing, students learn valuable skills in design, engineering, and problem-solving. It fosters collaboration as students work together to refine designs and troubleshoot printing challenges. 3D printing also introduces students to concepts of material science and manufacturing, preparing them for future careers in fields like engineering and product development. By embracing 3D printing, K-12 students gain a deeper understanding of technology and its potential to shape our world.
                  </p>
                  <div>
                    <button type="button" className="btn btn-warning">View More</button>
                    <button type="button" className="btn btn-danger ms-3">Just Watch</button>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        )}


        {isXXLarge && (
          <div className="container-xxl">
            <Container className="bg-gradient p-4">
              <Row className="align-items-center">
                <Col md={6}>
                  <img
                    src="3d-printer1.png"
                    alt="STEM Education"
                    className="img-content" style={{ boxShadow: '3px 5px 6px gray', borderRadius: '15px' }}
                  />
                </Col>
                <Col md={6} className="text-black p-4">
                  <h1 className="mb-4 stmsize">Printing</h1>
                  <span className='text-warning'>*************************</span>
                  <p className='text-about' style={{ textAlign: "justify" }}>
                    3D printing opens a world of creativity and innovation for K-12 students, allowing them to transform digital designs into tangible objects. This technology empowers students to explore their imaginations and bring ideas to life, whether they are creating prototypes, artistic sculptures, or functional tools. Through hands-on experience with 3D printing, students learn valuable skills in design, engineering, and problem-solving. It fosters collaboration as students work together to refine designs and troubleshoot printing challenges. 3D printing also introduces students to concepts of material science and manufacturing, preparing them for future careers in fields like engineering and product development. By embracing 3D printing, K-12 students gain a deeper understanding of technology and its potential to shape our world.
                  </p>
                  <div>
                    <button type="button" className="btn btn-warning">View More</button>
                    <button type="button" className="btn btn-danger ms-3">Just Watch</button>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        )}

      </div>
      <Part3 />
      <ServiceSection />
      <Card />
      <StemMindsCourse />
      <Aiml />
      <AvishkaarProduct />
      <Mapadd />
      <ChatbotToggle/>
    </div>
  );
}

export default Printing;