// src/App.js
import React, { useState } from 'react';
import './img.css';
import { NewHeader } from '../new-header/new-header';
import { NewFooter } from '../new-footer/new-footer';
import { Link } from 'react-router-dom';


const images = [
  // add more images as needed
  'many-image1 .jpg',
  'many-image3.jpg',
  // 'many-image4.jpg',
  'many-image5.jpg',
  'many-image6.jpg',
  'many-image7.jpg',
  'many-image8.jpg',
  'many-image2.png.jpg',
  'many-image9.jpg',
  'many-image10.jpg',
  'many-image11.jpg',
  'many-image12.jpg',
  'many-image13.jpg',
  'many-image14.jpg',

];

const ImgPop = () => {
  const [popupOpen, setPopupOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const openPopup = (image) => {
    setSelectedImage(image);
    setPopupOpen(true);
  };

  const closePopup = () => {
    setPopupOpen(false);
    setSelectedImage(null);
  };

  return (
    <div className="App">
      <div className="image-grid">
        <h1 className='gallery6'>Gallery</h1>
        <img id='divider' src="https://indiastemmission.com/images/icon/divider.png" alt="" />
        {/* <hr /> */}
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Image ${index}`}
            onClick={() => openPopup(image)}
            className="thumbnail"
          />
        ))}
      </div>

      {popupOpen && (
        <div className="popup">
          <div className="popup-content">
            <span className="close-button btn btn-danger" onClick={closePopup}>
              &times;
            </span>
            <img id='img1' src={selectedImage} alt="Selected" className="popup-image" />
          </div>
        </div>
      )}
      <Link to='/many-img' className='align-item-center btn btn-warning' variant="warning">View More</Link>
    </div>
  );
};

export default ImgPop;
