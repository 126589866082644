import React from 'react';
import StemProgram from '../stem-program/StemProgram'

import { Helmet } from 'react-helmet';

function HomePage() {
  return (
    <div className="home-page">

      <Helmet>
        <title>STEM WORLD | HOME</title>
      </Helmet>
      
      <StemProgram />

    </div>
  );
}

export default HomePage;
