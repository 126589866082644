import React from 'react';
import Header from '../header/header';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Carousel } from '../carousel/carousel';
import { Part3 } from '../Part_3/Part_3';
import Aiml from '../ai-ml/ai-ml';
import Mapadd from '../map-add/map-add';
import Card from '../card-img/card-img';
import { StemMindsCourse } from '../StemMindsCourse/StemMindsCourse';
import AvishkaarProduct from '../AvishkaarProduct/AvishkaarProduct';
import ServiceSection from '../OurPlatform/ServiceDetails/serviceSection';
import { useMediaQuery } from 'react-responsive';
import ChatbotToggle from '../Chatbot/chatbot';

function Drones() {

  const isSmall = useMediaQuery({ query: '(max-width: 576px)' });
  const isMedium = useMediaQuery({ query: '(min-width: 577px) and (max-width: 768px)' });
  const isLarge = useMediaQuery({ query: '(min-width: 769px) and (max-width: 992px)' });
  const isXLarge = useMediaQuery({ query: '(min-width: 993px) and (max-width: 1200px)' });
  const isXXLarge = useMediaQuery({ query: '(min-width: 1201px)' });

  return (
    <div>
      {/* <NewHeader /> */}
      <Carousel />
      <Header />
      <div>

        {isSmall && (
          <div className="container-sm" style={{ marginTop: "270px", marginBottom: "-250px" }}>
            <div>
              <div className='d-flex justify-content-center'>
                <img src="drone1.png" alt="STEM Education"  style={{ boxShadow: '3px 5px 6px gray', borderRadius: '15px' }} />
              </div>
              <div>
                <h1 className='text-center mt-1'>Drones</h1>
                <p style={{ textAlign: "justify" }}>Drones, also known as unmanned aerial vehicles (UAVs), offer K-12 students a captivating entry point into the world of technology and innovation. These versatile flying machines enable students to explore aerial photography, mapping, and delivery systems, providing practical experience in coding, engineering, and remote sensing technologies. By programming flight paths, analyzing data from onboard sensors, and understanding flight dynamics, students gain hands-on knowledge and skills. Drones also foster creativity as students develop solutions for real-world challenges like disaster response and environmental monitoring. With their growing use in industries such as agriculture and filmmaking, drones equip K-12 students with valuable insights and skills for the future of technology and exploration.</p>
                <button type="button" className="btn btn-warning">View More</button>
                <button type="button" className="btn btn-danger ms-3">Just Watch</button>
              </div>
            </div>
          </div>
        )}

        {isMedium && (
          <div className="container-sm mb-2" style={{ marginTop: "250px" }}>
            <div>
              <div className='d-flex justify-content-center'>
                <img src="drone1.png" alt="STEM Education" style={{ boxShadow: '3px 5px 6px gray', borderRadius: '15px' }}  />
              </div>
              <div>
                <h1 className='text-center mt-1'>Drones</h1>
                <p style={{ textAlign: "justify" }}>Drones, also known as unmanned aerial vehicles (UAVs), offer K-12 students a captivating entry point into the world of technology and innovation. These versatile flying machines enable students to explore aerial photography, mapping, and delivery systems, providing practical experience in coding, engineering, and remote sensing technologies. By programming flight paths, analyzing data from onboard sensors, and understanding flight dynamics, students gain hands-on knowledge and skills. Drones also foster creativity as students develop solutions for real-world challenges like disaster response and environmental monitoring. With their growing use in industries such as agriculture and filmmaking, drones equip K-12 students with valuable insights and skills for the future of technology and exploration.</p>
                <div>
                  <button type="button" className="btn btn-warning">View More</button>
                  <button type="button" className="btn btn-danger ms-3">Just Watch</button>
                </div>
              </div>
            </div>
          </div>
        )}

        {isLarge && (
          <div className="container-sm" style={{ marginTop: "250px" }}>
            <div>
              <div className='d-flex justify-content-center'>
                <img src="drone1.png" alt="STEM Education" style={{ boxShadow: '3px 5px 6px gray', borderRadius: '15px' }}  />
              </div>
              <div>
                <h1 className='text-center mt-1'>Drones</h1>
                <p style={{ textAlign: "justify" }}>Drones, also known as unmanned aerial vehicles (UAVs), offer K-12 students a captivating entry point into the world of technology and innovation. These versatile flying machines enable students to explore aerial photography, mapping, and delivery systems, providing practical experience in coding, engineering, and remote sensing technologies. By programming flight paths, analyzing data from onboard sensors, and understanding flight dynamics, students gain hands-on knowledge and skills. Drones also foster creativity as students develop solutions for real-world challenges like disaster response and environmental monitoring. With their growing use in industries such as agriculture and filmmaking, drones equip K-12 students with valuable insights and skills for the future of technology and exploration.</p>
                <div>
                  <button type="button" className="btn btn-warning">View More</button>
                  <button type="button" className="btn btn-danger ms-3">Just Watch</button>
                </div>
              </div>
            </div>
          </div>
        )}

        {isXLarge && (
          <div className="container-xxl" style={{ marginTop: "250px" }}>
            <Container className="bg-gradient p-4">
              <Row className="align-items-center">
                <Col md={6}>
                  <img
                    src="drone1.png"
                    alt="STEM Education"
                    className="img-content" style={{ boxShadow: '3px 5px 6px gray', borderRadius: '15px' }} 
                  />
                </Col>
                <Col md={6} className="text-black p-4">
                  <h1 className="stmsize">Drones</h1>
                  <span className='text-warning'>*******************************</span>
                  <p className='text-about' style={{ textAlign: "justify" }}>
                    Drones, also known as unmanned aerial vehicles (UAVs), offer K-12 students a captivating entry point into the world of technology and innovation. These versatile flying machines enable students to explore aerial photography, mapping, and delivery systems, providing practical experience in coding, engineering, and remote sensing technologies. By programming flight paths, analyzing data from onboard sensors, and understanding flight dynamics, students gain hands-on knowledge and skills. Drones also foster creativity as students develop solutions for real-world challenges like disaster response and environmental monitoring. With their growing use in industries such as agriculture and filmmaking, drones equip K-12 students with valuable insights and skills for the future of technology and exploration.
                  </p>
                  <div>
                    <button type="button" className="btn btn-warning">View More</button>
                    <button type="button" className="btn btn-danger ms-3">Just Watch</button>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        )}


        {isXXLarge && (
          <div className="container-xxl">
            <Container className="bg-gradient p-4">
              <Row className="align-items-center">
                <Col md={6}>
                  <img
                    src="drone1.png"
                    alt="STEM Education"
                    className="img-content" style={{ boxShadow: '3px 5px 6px gray', borderRadius: '15px' }} 
                  />
                </Col>
                <Col md={6} className="text-black p-4">
                  <h1 className="mb-4 stmsize">Drones</h1>
                  <span className='text-warning'>***********************************</span>
                  <p className='text-about' style={{ textAlign: "justify" }}>
                    Drones, also known as unmanned aerial vehicles (UAVs), offer K-12 students a captivating entry point into the world of technology and innovation. These versatile flying machines enable students to explore aerial photography, mapping, and delivery systems, providing practical experience in coding, engineering, and remote sensing technologies. By programming flight paths, analyzing data from onboard sensors, and understanding flight dynamics, students gain hands-on knowledge and skills. Drones also foster creativity as students develop solutions for real-world challenges like disaster response and environmental monitoring. With their growing use in industries such as agriculture and filmmaking, drones equip K-12 students with valuable insights and skills for the future of technology and exploration.
                  </p>
                  <div>
                    <button type="button" className="btn btn-warning">View More</button>
                    <button type="button" className="btn btn-danger ms-3">Just Watch</button>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        )}
      </div>
      <Part3 />
      <ServiceSection />
      <Card />
      <StemMindsCourse />
      <Aiml />
      <AvishkaarProduct />
      <Mapadd />
      <ChatbotToggle/>
    </div>
  );
}

export default Drones;