import React from 'react';
import CardCss from './card.module.css';

const divStyle = {
    // backgroundImage: 'url("bac-img1.png")',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    width: '102%',
};

const Card = () => {
    return (
        <div style={divStyle} className={CardCss.app}>
            <div>
                <h2 className={CardCss.mainTitle}>
                    A Complete Platform for AI and Robotics Education
                    <p className={CardCss.title}>
                        "STEMWorld integrates its educational platform, STEMVerse, to support our innovative products and services. <br />We offer comprehensive teacher training and school lab setups, empowering educators and institutions <br /> to deliver engaging STEM education."
                    </p>
                </h2>
                <div className={CardCss.container}>
                    <div className={CardCss.card} >
                        <img className={CardCss.cardImage} src="coding23.png" alt="StemVerse" />
                        <h1 className={CardCss.cardTitle}>STEM Verse</h1>
                    </div>
                    <div className={CardCss.card}>
                        <img className={CardCss.cardImage} src="InnovationLab1.jpg" alt="Innovation Lab" />
                        <h1 className={CardCss.cardTitle}>Innovation Lab</h1>
                    </div>
                    <div className={CardCss.card}>
                        <img className={CardCss.cardImage} src="iot4.png.jpg" alt="Teacher Development Program" />
                        <h1 className={CardCss.cardTitle}>Teacher Development Program</h1>
                    </div>
                    <div className={CardCss.card}>
                        <img className={CardCss.cardImage} src="kides.png" alt="Educational Kits" />
                        <h1 className={CardCss.cardTitle}>Educational Kits</h1>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Card;
