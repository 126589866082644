// src/App.js
import React, { useState } from 'react';
import './gallery.css';
// import { NewHeader } from '../new-header/new-header';
// import { NewFooter } from '../new-footer/new-footer';
import { Link } from 'react-router-dom';


const images = [
    // 'many-Image1 .jpg',
    // 'https://media.gettyimages.com/id/814510514/photo/puerto-rican-students-build-a-battle-robot-for-the-national-robotics-competition.jpg?s=1024x1024&w=gi&k=20&c=CVPf1h9hgl2NKA0eHIIAsGtFNUyziWu3a9O_WBNxxAY=',
    // "https://img.freepik.com/premium-photo/robotics-competition-with-students-operating-their-intricate-creations-showcasing-their-engineer_741910-46617.jpg",
    // 'https://c8.alamy.com/comp/EGW3W1/first-tech-challenge-regional-championship-held-at-nyu-polytechnic-EGW3W1.jpg',
    // 'https://media.gettyimages.com/id/814510488/photo/female-students-build-battle-robots-for-the-national-robotics-competition.jpg?s=612x612&w=0&k=20&c=hSxDIGpXjg4Qjgzn-rhV4Og3xsST9ymA6c--bsqIvI8=',
    // 'https://media.gettyimages.com/id/816250440/photo/members-of-the-afghan-all-girls-robotics-team-work-with-their-robot-in-the-practice-area-july.jpg?s=612x612&w=0&k=20&c=NwGYfcZZBhwF4cVr2_eeZXKGz2MU8CresNRq0xE0QrM=',
    // add more images as needed
    'many-image1 .jpg',
    // 'many-image3.jpg',
    'many-image4.jpg',
    'many-image5.jpg',
    'many-image6.jpg',
    'many-image2.png.jpg'




];

  export const Gallery = () => {
    const [popupOpen, setPopupOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    const openPopup = (image) => {
        setSelectedImage(image);
        setPopupOpen(true);
    };

    const closePopup = () => {
        setPopupOpen(false);
        setSelectedImage(null);
    };

    return (
        <div className="App">
            <div className="image-grid">
                <h1 className='gallery6 text-center' style={{textAlign:"center"}}>Gallery</h1>
                {/* <img id='divider' src="https://indiastemmission.com/images/icon/divider.png" alt="" /> */}
                {/* <hr /> */}
                {images.map((image, index) => (
                    <img
                        key={index}
                        src={image}
                        alt={`Image ${index}`}
                        onClick={() => openPopup(image)}
                        className="thumbnail"
                    />
                ))}
            </div>

            {popupOpen && (
                <div className="popup">
                    <div className="popup-content">
                        <span className="close-button btn btn-danger" onClick={closePopup}>
                            &times;
                        </span>
                        <img id='img1' src={selectedImage} alt="Selected" className="popup-image" />
                    </div>
                </div>
            )}
            <Link to='/many-img' className='align-item-center btn btn-warning mb-1' variant="warning">View More</Link>
        </div>
    );
};

export default Gallery;
