import React from "react";
import "./nationalCurriculum.css"; // Create this CSS file for custom styles
import { Link } from "react-router-dom";
import Header_ixl from "../Header_IXL/header_ixl";

const NationalCurriculum = () => {
  return (
    <div className="container">
      
      <Header_ixl />

      <div className="main-content">
        <div className="left-content">
          <h1>India</h1>
          <h2>IXL alignment to the India national maths curriculum</h2>
          <div className="description">
            IXL's skills are aligned to the National Council of Education
            Research and Training Syllabus, providing comprehensive coverage of
            maths concepts and applications. With IXL's national curriculum
            alignments, you can easily find unlimited practice questions
            specifically tailored to each learning objective.
          </div>
        </div>
        <div className="right-content">
          <img
            src="https://upload.wikimedia.org/wikipedia/en/4/41/Flag_of_India.svg"
            alt="India Flag"
            className="india-flag"
          />
        </div>
      </div>

      <div className="practice-skills">
        <p>
          Click on a class to view the national curriculum, find practice skills
          and track performance.
        </p>
        <div className="class-list">
          <span className="class-item">Class I</span>
          <span className="class-item">Class II</span>
          <span className="class-item">Class III</span>
          <span className="class-item">Class IV</span>
          <span className="class-item">Class V</span>
          <span className="class-item">Class VI</span>
          <span className="class-item">Class VII</span>
          <span className="class-item">Class VIII</span>
          <span className="class-item">Class IX</span>
          <span className="class-item">Class X</span>
          <span className="class-item">Class XI</span>
          <span className="class-item">Class XII</span>
        </div>
      </div>
    </div>
  );
};

export default NationalCurriculum;
