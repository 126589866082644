import React from 'react';
import './ourPrograms.css';

function OurPrograms() {
  return (
    <div className="container">
      <h2 className="heading" style={{fontFamily:' "Dancing Script", cursive',fontSize:"60px",color:"red"}}>Programs</h2>
      <div className="circle-container">
        <div>
          <div className="circle">
            <img src="lego-education.jpg" alt="Image 1" />
          </div>
          <div className="age-label label1">Age 5+</div>
        </div>
        <div>
          <div className="circle">
            <img src="logo-boost.jpg" alt="Image 2" />
          </div>
          <div className="age-label label2">Age 9+</div>
        </div>
        <div>
          <div className="circle">
            <img src="Educational-Robotics.jpg" alt="Image 3" />
          </div>
          <div className="age-label label3" style={{background:"maroon"}}>Age 12+</div>
        </div>
      </div>
      <div className="program-list">
        <div className="program-item">
          <ul>
            <li>LEGO WE DO 2.0</li>
            <li>PROGRAMMING IN SCRATCH 3.0</li>
            <li>ROBOTICS FOR KIDS</li>
            <li>MIT APP INVENTOR</li>
            <li>PYTHON TURTLE</li>
            <li>BASIC ELECTRIC CIRCUITS</li>
            <li>CIRCUIT SIMULATION</li>
            <li>ATL TINKERING</li>
            <li>ARTS (BASIC LEVEL)</li>
            <li>SCHOOL PROJECTS</li>
          </ul>
        </div>
        <div className="program-item">
          <ul>
            <li>ARTIFICIAL INTELLIGENCE (AI)</li>
            <li>AI WITH ROBOTICS</li>
            <li>APP DEVELOPMENT</li>
            <li>GAME DEVELOPMENT</li>
            <li>WEB DEVELOPMENT</li>
            <li>IOT WITH RASPBERRY</li>
            <li>DRONE TECHNOLOGY</li>
            <li>CYBER SECURITY FOR KIDS</li>
            <li>ENTREPRENEURSHIP SKILLS FOR KIDS</li>
            <li>ARTS (ADVANCED)</li>
            <li>SCHOOL PROJECTS</li>
          </ul>
        </div>
        <div className="program-item">
          <ul>
            <li>LEGO MINDSTROMS</li>
            <li>SCRATCH WITH AI</li>
            <li>ROBOTICS</li>
            <li>ARDUINO PROGRAMMING</li>
            <li>PYTHON</li>
            <li>HTML, MS-OFFICE APP DEVELOPMENT</li>
            <li>DIY ELECTRONICS</li>
            <li>IOT & 3D PRINTING</li>
            <li>GRAPHIC DESIGN</li>
            <li>ARTS (MEDIUM)</li>
            <li>SCHOOL PROJECTS</li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default OurPrograms;
