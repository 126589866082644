// ContactInfo.jsx
import React from "react";

const ContactInfo = () => {
    return (
        <div className="contact-container">
            <div className="left">
                <div className="contact-info">
                    <div className="contact-item">
                        <i className="fas fa-map-marker-alt icon"></i>
                        <div>
                            <h3>Our Address</h3>
                            <p>
                                1st Floor, Plot 151, Huda Layout, Nallagandla,
                                Serilingampally, Hyderabad, 500019.
                            </p>
                        </div>
                    </div>
                    <div className="contact-item">
                        <i className="fas fa-envelope icon"></i>
                        <div>
                            <h3>E-mail</h3>
                            <p>stemworld.hyd@gmail.com</p>
                        </div>
                    </div>
                    <div className="contact-item">
                        <i className="fas fa-phone-alt icon"></i>
                        <div>
                            <h3>Phone</h3>
                            <p>+91 81214-81525 <br /> +91 81216-29315</p>
                        </div>
                    </div>
                    <div className="contact-item">
                        <i className="fas fa-clock icon"></i>
                        <div>
                            <h3>Timings</h3>
                            <p>Monday - Saturday: 09.00AM - 06.00 PM</p>
                            <p>Sunday: 10.00AM - 12.00Noon</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="right">
                {/* You can add any other content here if needed */}
            </div>
        </div>
    );
};

export default ContactInfo;
