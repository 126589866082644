import React from 'react'
import './map-add.css'

function Mapadd() {
    return (
        <>
            <address>
                {/* biodicsldckmdcsldc */}
            </address>
            <div>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3805.70327453714!2d78.30811609999999!3d17.473910600000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9300237ab8bb%3A0x527c80e6a73ef22f!2sSTEM%20WORLD%20Robotics%20and%20Coding%20Centre!5e0!3m2!1sen!2sin!4v1718627534332!5m2!1sen!2sin" width="100%" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>


            </div>
        </>
    )
}

export default Mapadd