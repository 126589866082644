import React, { useRef } from 'react';
import './AvishkaarProduct.css';

const images = [
    <div className="AvishkaarProduct m-4">
        <img src="product3.jpg" />
        <h5 style={{ marginTop: "12px", paddingBottom: "15px" }}>JUNIOR ROBOTICS KIT</h5>
        {/* <p className='text-center'>Lorem ipsum soluta.</p> */}
    </div>,
    <div className="AvishkaarProduct m-4">
        <img src="product.jpg" />
        <h5 style={{ marginTop: "12px", paddingBottom: "15px" }}>ROBOTICS ROVER KIT</h5>
        {/* <p className='text-center'>Lorem ipsum soluta.</p> */}
    </div>,
    <div className="AvishkaarProduct m-4">
        <img src="product5.jpg" />
        <h5 style={{ marginTop: "12px", paddingBottom: "15px" }}>GEOGRAPHY AI & IOT KIT</h5>
        {/* <p className='text-center'>Lorem ipsum soluta.</p> */}
    </div>,
    <div className="AvishkaarProduct m-4">
        <img src="product6.jpg" />
        <h5 style={{ marginTop: "12px", paddingBottom: "15px" }}>DIY ROBOTICS ARM KIT</h5>
        {/* <p className='text-center'>Lorem ipsum soluta.</p> */}
    </div>,
    <div className="AvishkaarProduct m-4">
        <img src="product7.jpg" />
        <h5 style={{ marginTop: "12px", paddingBottom: "15px" }}>DIY ELECTRONICS KIT</h5>
        {/* <p className='text-center'>Lorem ipsum soluta.</p> */}
    </div>,
    // <div className="AvishkaarProduct m-4">
    //     <img src="card-6.jpg" />
    //     <h5 style={{marginTop:"12px",paddingBottom:"15px"}}>Some Text</h5>
    // </div>
];

const AvishkaarProduct = () => {
    const scrollContainerRef = useRef(null);

    const scroll = (direction) => {
        if (direction === 'left') {
            scrollContainerRef.current.scrollBy({ left: -300, behavior: 'smooth' });
        } else {
            scrollContainerRef.current.scrollBy({ left: 300, behavior: 'smooth' });
        }
    };

    return (
        <div className='image-gallery2'>
            <h3 className='text-center pt-5' style={{ fontFamily: ' "Dancing Script", cursive', fontSize: "60px", fontStyle: "bold" }}>Products</h3>
            <div className="gallery-container">
                <button className="scroll-button" onClick={() => scroll('left')}>&#9664;</button>
                <div className="image-gallery" ref={scrollContainerRef}>
                    {images.map((image, index) => (
                        // <img key={index} src={image} alt={`Image ${index + 1}`} />
                        <div key={index}>
                            {image}
                        </div>
                    ))}
                </div>
                <button className="scroll-button" onClick={() => scroll('right')}>&#9654;</button>
            </div>
        </div>
    );
};

export default AvishkaarProduct;
