import React from 'react'
import Top from '../top/top';
import HeroSection from '../hero-section/hero-section';
import QuarkyTech from '../querky-tech/querky-tech';
import style from './school-program.module.css'
import { Helmet } from 'react-helmet';

export const SchoolProgram = () => {
    return (
        <div>
            <Helmet>
                <title>STEM WORLD | School-Program</title>
            </Helmet>
        <div className={style.school_program}>.</div>
            <Top />
            <HeroSection />
            {/* <AdvanceConcept /> */}
            {/* <TechnologiesSection /> */}
            <QuarkyTech />
            {/* <WhyQuarkySection /> */}
        </div>
    )
}
