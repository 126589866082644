import React from "react";
import '../new-footer/new-footer.css'
import { Link } from "react-router-dom";

export function NewFooter() {
    return (
        <div>
            <div className='footer-header'>
                <div className='social-media'>
                    <a style={{ marginRight: "-17px" }} href='https://www.facebook.com/profile.php?id=61556281820676'><span style={{ color: "white", }} className="bi bi-whatsapp  text-#fff"></span></a>
                    <a style={{ marginRight: "-17px" }} href='https://www.facebook.com/profile.php?id=61556281820676'><span style={{ color: "white", }} className="bi bi-facebook text-#fff"></span></a>
                    <a style={{ marginRight: "-17px" }} href='https://x.com/theSTEMWORLD'><span style={{ color: "white" }} className="fa-brands fa-x-twitter text-#fff"></span></a>
                    <a style={{ marginRight: "-17px" }} href='#'><span style={{ color: "white" }} className="bi bi-linkedin text-#fff"></span></a>
                    <a style={{ marginRight: "-17px" }} href='https://www.instagram.com/stemworld_india/'><span style={{ color: "white" }} className="bi bi-instagram text-#fff"></span></a>
                    <a style={{ marginRight: "-17px" }} href='https://www.youtube.com/channel/UC04XepbKk66-4s_0v1cDOPg'><span style={{ color: "white" }} className="bi bi-youtube text-#fff"></span></a>
                </div>
                <div className='logo'>
                    <h2 className="text-white">STEM WORLD</h2>
                </div>
                <div className='contacts' style={{ marginTop: "10px" }}>
                    <a className="text-white" href='#'><span className='bi bi-telephone-fill text-black' style={{fontSize:"20px",marginRight:"-10px"}}></span> +91 8121481525  +918121629315</a>
                    <a className="text-danger text-white me-5" href='#'><span className='bi bi-envelope-fill text-black' style={{fontSize:"20px",marginRight:"-10px"}}></span> stemworld.hyd@gmail.com</a>
                </div>
            </div>
            <div className='footer-body'>
                <div className="side-footer">
                    <div className='title'><a className='title-text' href='#'>Shop</a></div>
                    <div><a className="" href='#'>Hardware</a></div>
                    <div><a className="" href='#'>Courses</a></div>
                    <div><a className="" href='#'>Software</a></div>
                    <div><a className="" href='#'>Products</a></div>
                    <div><a className="" href='#'>Kits</a></div>
                </div>
                <div>
                    <div className='title'><a className='title-text' href='#'>About Us</a></div>
                    <div><a className="" href='#'>Vision</a></div>
                    <div>  <Link className="" to='/service'>Service</Link></div>
                    <div><a className="" href='#'>Team</a></div>
                    <div><a className="" href='#'>Partners</a></div>
                    <div><a className="" href='#'>News</a></div>
                </div>
                <div>
                    <div className='title'><a className='title-text' href='#'>Resources</a></div>
                    <div><a className="" href='#'>NEP-2020</a></div>
                    <div><a className="" href='#'>Free Tutorials</a></div>
                    <div><a className="" href='#'>Softwares</a></div>
                    <div><a className="" href='#'>K-12 STEM</a></div>
                    <div><a className="" href='#'>Blogs</a></div>
                </div>
                <div>
                    <div className='title'><a className='title-text' href='#'>Labs</a></div>
                    <div><a className="" href='#'>Schools</a></div>
                    <div><a className="" href='#'>Activity Centers</a></div>
                    <div><a className="" href='#'>Teacher Training</a></div>
                    <div><a className="" href='#'>ATL Labs</a></div>
                    <div><a className="" href='#'>Partners Schools</a></div>
                </div>
                <div>
                    <div className='title'><a className='title-text' href='#'>Events</a></div>
                    <div><a className='' href='#'>Workshops</a></div>
                    <div><a className='' href='#'>Community Classes</a></div>
                    <div><a className='' href='#'>Comptetitions</a></div>
                    <div><a className='' href='#'>Hackthons</a></div>
                </div>
                <div>
                    <div className='title'><a className='title-text' href='#'>Contact Us</a></div>
                    <div><a className="" href='#'>Support</a></div>
                    <div><a className="" href='#'>Feedback</a></div>
                    <div><a className="" href='#'>Social Media</a></div>
                </div>
            </div>

            <div className='footer-footer-mobile'>
                <div>StemWorld © 2024.All rights reserved.</div>
                <div>Terms & Conditions</div>
                <div>Privacy Policy</div>
                <div>Shipping & Cancellations</div>
            </div>

            <div className='footer-footer-desktop'>
                <div>StemWorld © 2024.All rights reserved.</div>
                <div>Terms & Conditions | Privacy Policy | Shipping & Cancellations</div>
            </div>
        </div>
    )
}