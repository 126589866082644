import React from 'react'
import { Link } from 'react-router-dom'

function Header_ixl() {
    return (
        <div>
            <div className="header" style={{ backgroundColor: "#0dcaf0" }}>
                <span className="header-item">India:</span>
                <span className="header-item">MATHS</span>
                <span className="header-item">ENGLISH</span>
            </div>

            <div className="info-bar">
                <span className="info-item"><Link to="/national-curriculum">Info</Link></span>
                <span className="info-item"><Link to="/class-1">Class I</Link></span>
                <span className="info-item"><Link to="/class-2">Class II</Link></span>
                <span className="info-item"><Link to="/class-3">Class III</Link></span>
                <span className="info-item"><Link to="/class-4">Class IV</Link></span>
                <span className="info-item"><Link to="/class-5">Class V</Link></span>
                <span className="info-item"><Link to="/class-6">Class VI</Link></span>
                <span className="info-item"><Link to="/class-7">Class VII</Link></span>
                <span className="info-item"><Link to="/class-8">Class VIII</Link></span>
                <span className="info-item"><Link to="/class-9">Class IX</Link></span>
                <span className="info-item"><Link to="/class-10">Class X</Link></span>
                <span className="info-item"><Link to="/class-11">Class XI</Link></span>
                <span className="info-item"><Link to="/class-12">Class XII</Link></span>
            </div>
        </div>
    )
}

export default Header_ixl
