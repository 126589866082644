
import './StemMindsCourse.css';

export function StemMindsCourse() {
    return (
        <div className='StemMindsCourse_maindiv  courses-img'>
            <h1 className='text-center pt-4 ' style={{ fontFamily: ' "Dancing Script" cursive', fontSize: "60px", color: "orange", fontWeight: "bold" }}>Courses</h1>
            <div className='d-flex justify-content-center  stem-Course'>
                <div className="StemMindsCourse m-4">
                    <img src="Manu-1.png" />
                    <h5 style={{ color: "#4169E1" }}>Electronics</h5>
                    <p className='text-center'>Sparking Curiosity <br /> through Circuits</p>
                </div>
                <div className="StemMindsCourse  m-4">
                    <img src="Manu-2.png" />
                    <h5 style={{ color: "#4169E1" }}>Robotics</h5>
                    <p className='text-center'>Robotics: Building<br /> tomorrow's tech today</p>
                </div>
                <div className="StemMindsCourse  m-4">
                    <img src="Manu-3.png" />
                    <h5 style={{ color: "#4169E1" }}>3D Printing</h5>
                    <p className='text-center'>Transform Ideas <br />into Real Objects</p>
                </div>
                <div className="StemMindsCourse  m-4">
                    <img src="Manu-4.png" />
                    <h5 style={{ color: "#4169E1" }}>Drones</h5>
                    <p className='text-center'>Navigate the Skies:<br />Code your Drone's Journey</p>
                </div>
            </div>

            <div className='d-flex justify-content-center stem-Course '>
                <div className="StemMindsCourse m-4">
                    <img src="Manu-8.png" />
                    <h5 style={{ color: "#4169E1" }}>Coding</h5>
                    <p className='text-center'>Cultivating Kids Creativity through Computational Thinking</p>
                </div>
                <div className="StemMindsCourse m-4">
                    <img src="Manu-6.png" />
                    <h5 style={{ color: "#4169E1" }}>IoT</h5>
                    <p className='text-center'>Connecting the Dots:<br /> Code for a Smarter World</p>
                </div>
                <div className="StemMindsCourse m-4">
                    <img src="Manu-7.png" />
                    <h5 style={{ color: "#4169E1" }}>VR</h5>
                    <p className='text-center' style={{ marginTop: '-9px' }}>Transforming Learning through Virtual and Augmented Realities</p>
                </div>
                <div className="StemMindsCourse m-4">
                    <img src="Manu-9.png" />
                    <h5 style={{ color: "#4169E1" }}>AI</h5>
                    <p className='text-center'>Harness the Power of AI <br />for Smarter Learning</p>
                </div>
            </div>
        </div>
    )
}
