import React from 'react';
import Header from '../header/header';
import { Container, Row, Col } from 'react-bootstrap';
import { Carousel } from '../carousel/carousel';
import { Part3 } from '../Part_3/Part_3';
import Aiml from '../ai-ml/ai-ml';
import Mapadd from '../map-add/map-add';
import Card from '../card-img/card-img';
import { StemMindsCourse } from '../StemMindsCourse/StemMindsCourse';
import AvishkaarProduct from '../AvishkaarProduct/AvishkaarProduct';
import ServiceSection from '../OurPlatform/ServiceDetails/serviceSection';
import './StemProgram.css'; // Import the CSS file
import { useMediaQuery } from 'react-responsive';
import Chatbot from '../Chatbot/chatbot';


function StemProgram() {

  const isSmall = useMediaQuery({ query: '(max-width: 576px)' });
  const isMedium = useMediaQuery({ query: '(min-width: 577px) and (max-width: 768px)' });
  const isLarge = useMediaQuery({ query: '(min-width: 769px) and (max-width: 992px)' });
  const isXLarge = useMediaQuery({ query: '(min-width: 993px) and (max-width: 1200px)' });
  const isXXLarge = useMediaQuery({ query: '(min-width: 1201px)' });

  return (
    <div className="stem-program">
      <Carousel />
      <Header />

      <div>

        {isSmall && (
          <div className="container-sm" style={{ marginTop: "270px", marginBottom: "-250px" }}>
            <div>
              <div className='d-flex justify-content-center'>
                <img src="many-image15.jpg" alt="STEM Education" style={{ boxShadow: '3px 5px 6px gray', borderRadius: '15px' }} />
              </div>
              <div>
                <h1 className='text-center mt-1'>About Us</h1>
                <p style={{ textAlign: "justify" }}>STEM WORLD, founded by IIT & NIT alumni, is dedicated to providing K-12 students with a hands-on, technology-driven education. Our mission is to nurture future-ready students by embedding next-gen skills into their learning experience. We collaborate with industry experts and educators to offer a student-centric experimental platform that fosters critical thinking and innovation.</p>
                <button type="button" className="btn btn-warning">View More</button>
                <button type="button" className="btn btn-danger ms-3">Just Watch</button>
              </div>
            </div>
          </div>
        )}

        {isMedium && (
          <div className="container-sm mb-2" style={{ marginTop: "250px" }}>
            <div>
              <div className='d-flex justify-content-center'>
                <img src="many-image15.jpg" alt="STEM Education" style={{ boxShadow: '3px 5px 6px gray', borderRadius: '15px' }} />
              </div>
              <div>
                <h1 className='text-center mt-1'>About Us</h1>
                <p style={{ textAlign: "justify" }}>STEM WORLD, founded by IIT & NIT alumni, is dedicated to providing K-12 students with a hands-on, technology-driven education. Our mission is to nurture future-ready students by embedding next-gen skills into their learning experience. We collaborate with industry experts and educators to offer a student-centric experimental platform that fosters critical thinking and innovation.</p>
                <div>
                  <button type="button" className="btn btn-warning">View More</button>
                  <button type="button" className="btn btn-danger ms-3">Just Watch</button>
                </div>
              </div>
            </div>
          </div>
        )}

        {isLarge && (
          <div className="container-sm" style={{ marginTop: "250px" }}>
            <div>
              <div className='d-flex justify-content-center'>
                <img src="many-image15.jpg" alt="STEM Education" style={{ boxShadow: '3px 5px 6px gray', borderRadius: '15px' }} />
              </div>
              <div>
                <h1 className='text-center mt-1'>About Us</h1>
                <p style={{ textAlign: "justify" }}>STEM WORLD, founded by IIT & NIT alumni, is dedicated to providing K-12 students with a hands-on, technology-driven education. Our mission is to nurture future-ready students by embedding next-gen skills into their learning experience. We collaborate with industry experts and educators to offer a student-centric experimental platform that fosters critical thinking and innovation.</p>
                <div>
                  <button type="button" className="btn btn-warning">View More</button>
                  <button type="button" className="btn btn-danger ms-3">Just Watch</button>
                </div>
              </div>
            </div>
          </div>
        )}

        {isXLarge && (
          <div className="container-xxl" style={{ marginTop: "250px" }}>
            <Container className="bg-gradient p-4">
              <Row className="align-items-center">
                <Col md={6}>
                  <img
                    src="many-image15.jpg"
                    alt="STEM Education"
                    className="img-content" style={{ boxShadow: '3px 5px 6px gray', borderRadius: '15px' }}
                  />
                </Col>
                <Col md={6} className="text-black p-4">
                  <h1 className="stmsize">About Us</h1>
                  <span className='text-warning'>************************************</span>
                  <p className='text-about' style={{ textAlign: "justify" }}>
                    STEM WORLD, founded by IIT & NIT alumni, is dedicated to providing K-12 students with a hands-on, technology-driven education. Our mission is to nurture future-ready students by embedding next-gen skills into their learning experience. We collaborate with industry experts and educators to offer a student-centric experimental platform that fosters critical thinking and innovation.
                  </p>
                  <div>
                    <button type="button" className="btn btn-warning">View More</button>
                    <button type="button" className="btn btn-danger ms-3">Just Watch</button>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        )}


        {isXXLarge && (
          <div className="container-xxl">
            <Container className="bg-gradient p-4">
              <Row className="align-items-center">
                <Col md={6}>
                  <img
                    src="many-image15.jpg"
                    alt="STEM Education"
                    className="img-content" style={{ boxShadow: '3px 5px 6px gray', borderRadius: '15px' }}
                  />
                </Col>
                <Col md={6} className="text-black p-4">
                  <h1 className="mb-4 stmsize">About Us</h1>
                  <span className='text-warning'>*********************************</span>
                  <p className='text-about' style={{ textAlign: "justify" }}>
                    STEM WORLD, founded by IIT & NIT alumni, is dedicated to providing K-12 students with a hands-on, technology-driven education. Our mission is to nurture future-ready students by embedding next-gen skills into their learning experience. We collaborate with industry experts and educators to offer a student-centric experimental platform that fosters critical thinking and innovation.
                  </p>
                  <div>
                    <button type="button" className="btn btn-warning">View More</button>
                    <button type="button" className="btn btn-danger ms-3">Just Watch</button>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        )}

      </div>

      <Part3 />
      <ServiceSection />
      <Card />
      <StemMindsCourse />
      <Aiml />
      <AvishkaarProduct />
      <Mapadd />
      <Chatbot/>
    </div>
  );
}

export default StemProgram;