import './samlabs.css';
export function Samlabs() {
    return (
        <div style={{backgroundColor:"#f4f6fc"}}>
            <div className="Samlabs">
                <img src="samblabs.png" />
                <h1>Using Inquiry-Based Learning for STEM Concepts</h1>
                <p>When hearing the term inquiry-based learning, many educators are apt to imagine the concept revolves around asking the student what they want to learn. While that can be part of inquiry-based learning, it’s really much more than that. </p>
                <h3>What Is Inquiry-Based Learning?</h3>
                <p>Inquiry-based learning is curiosity driven. The main purpose of this type of learning is to pique the curiosity of the student and encourage them to engage on a deep level. With inquiry-based learning, students are more interested and engaged in what they are learning than with traditional methods of education. </p>
                <p>While it is an entirely different form of learning and teaching, inquiry-based learning can actually be better for teachers. One of the main inquiry-based learning benefits is it transfers the authority of learning to the student which motivates them in their learning endeavors. Students learn to seek out answers instead of being complacent without them, creating a strong and cohesive learning environment.</p>
                <p>With this learning approach, teachers don’t simply ask students what they want to know about, they introduce them to things, pique their curiosity, and then set them free to start learning. It is a way to build engaged students who develop a deeper understanding of the things that spark their natural curiosities. </p>
                <h3>Steps to Inquiry-Based Learning</h3>
                <p>Inquiry-based learning typically follows a short learning process that moves the learner from questioning to reflecting.</p>
                <p>It involves student exploration of a topic that has sparked something within the learner.  The student experiences a learning journey that begins with curiosity and ends with understanding, and oftentimes, further questioning and exploration. </p>
                <p>The common steps on the journey of inquiry-based learning are: </p>
                <h3>1. Curiosity is Sparked and Student Begins to Ask Questions</h3>
                <p>When the learner’s curiosity is initially sparked, teachers will want to act on that interest and wonder. This is the time to encourage students to dive deep to find answers through an organized pitch. </p>
                <p>Have students structure their thoughts and questions so that they can engage in learning with intention and direction.</p>
                <h2>2. Create Learning Goals</h2>
                <p>Once the learner is interested in a topic and asking questions, the teacher and learner should begin setting goals together. </p>
                <p>Determine what information they’re trying to learn and what questions need to be answered before they feel satisfied with their learning. All of the research and investigating will be focused on reaching these learning goals. </p>
                <p>Both the learner and teacher should remember that learning is fluid and therefore goals may change as research commences. Remaining open to change as investigating happens is an essential part of the inquiry-based learning process. </p>
                <h3>3. Encourage Research During Class Time</h3>
                <p>Inquiry-based learning leads to personal research and discovery. However, it’s important to ensure that students also work on research and learning during class time. </p>
                <p>Researching during class gives the teacher the ability to provide guidance, model research skills, and to provide help as the student engages with the topic. </p>
                <h3>4. Time for Presentations</h3>
                <p>Once goals have been reached and questions have been answered, the student should be prepared to present their findings to the class. They should be able to communicate what they have learned, teaching others in the process. </p>
                <p>Presentations can include STEM elements such as tech presentation tools, labs, and other resources that help to showcase what has been discovered. </p>
                <h3>5. Reflection</h3>
                <p>After goals have been reached and the student has been able to effectively communicate their findings, they will want to take some time to reflect on the entire process. This should include discussing what investigation methods were effective and which ones weren’t. </p>
                <p>As they consider the various aspects of the investigation, they will be setting themselves up for future inquiry-based learning success. The more they understand what works for them, the more successful their research and learning will be. </p>
                <h3>Inquiry-Based STEM Learning</h3>
                <p>The inquiry-based learning process goes hand-in-hand with STEM concepts in the classroom. Students are encouraged to be interested and experiment as lifelong learners through STEM education. Since inquiry-based learning fosters creativity and critical thinking, it naturally integrates with STEM.</p>
                <p>STEM education that is inquiry-based emphasizes practical applications and original approaches to problem-solving. It uses investigative methods of teaching and learning where students are given the chance to look into a situation, look for potential answers, make observations, pose questions, try out ideas, and use their imagination and intuition.</p>
                <p>To encourage inquiry-based STEM learning, teachers should start with a subject they know their students will be curious about. They should consider the STEM topics that will spark interest and questions. </p>
                <p>A great way to do this is by presenting a problem involving STEM topics that students will want to work towards solving. </p>
                <p>With a chosen topic, teachers and students can dive together into the inquiry-based learning process. As students begin to ask questions, create goals, perform research, and arrive at an answer, teachers will be on hand to guide, encourage, and support them. </p>
                <h3>Use STEM WORLD for Inquiry-Based Learning</h3>
                <p>STEM WORLD is a great resource for inquiry-based STEM learning. With tools and resources ready for use, teachers can provide STEM WORLD access to students during their investigation and research process. </p>
                <p>Along with STEM learning, inquiry-based learning is the education of the future. The two concepts create a forward-thinking, cohesive learning path that should be utilized in all schools. </p>
            </div>
        </div>
    )
}
