import React from 'react';
import PropTypes from 'prop-types';
import './ProductCard.css';

const ProductCard = ({ image, name, price, isSoldOut, isQuickBuy, bannerImage }) => {
  return (
    <div 
      className="product-card"
      style={{ backgroundImage: bannerImage ? `url(${bannerImage})` : 'none' }}
    >
      <img src={image} alt={name} className="product-image" />
      {isSoldOut && <span className="sold-out">Sold Out</span>}
      {isQuickBuy && <button className="quick-buy">Quick buy</button>}
      <h3 className="product-name">{name}</h3>
      <p>Price: {price}</p>
      {/* other markup */}
    </div>
  );
};

ProductCard.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  isSoldOut: PropTypes.bool,
  isQuickBuy: PropTypes.bool,       
//   bannerImage: PropTypes.string,
};




ProductCard.defaultProps = {
  isSoldOut: false,
  isQuickBuy: false,
  bannerImage: null,
};

export default ProductCard;
