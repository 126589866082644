import React from 'react';
import Header from '../header/header';
import { Container, Row, Col } from 'react-bootstrap';
// import './IOT.css'
import { Carousel } from '../carousel/carousel';
// import { NewHeader } from '../new-header/new-header';
// import { NewFooter } from '../new-footer/new-footer';
// import { Gallery } from '../gallery/gallery';
import { Part3 } from '../Part_3/Part_3';
import Mapadd from '../map-add/map-add';
import Aiml from '../ai-ml/ai-ml';
import Card from '../card-img/card-img';
import { StemMindsCourse } from '../StemMindsCourse/StemMindsCourse';
import AvishkaarProduct from '../AvishkaarProduct/AvishkaarProduct';
import ServiceSection from '../OurPlatform/ServiceDetails/serviceSection';
import { useMediaQuery } from 'react-responsive';
import ChatbotToggle from '../Chatbot/chatbot';

function IOTLab() {

  const isSmall = useMediaQuery({ query: '(max-width: 576px)' });
  const isMedium = useMediaQuery({ query: '(min-width: 577px) and (max-width: 768px)' });
  const isLarge = useMediaQuery({ query: '(min-width: 769px) and (max-width: 992px)' });
  const isXLarge = useMediaQuery({ query: '(min-width: 993px) and (max-width: 1200px)' });
  const isXXLarge = useMediaQuery({ query: '(min-width: 1201px)' });

  return (
    <div>
      {/* <NewHeader /> */}
      <Carousel />
      <Header />
      <div>

        {isSmall && (
          <div className="container-sm" style={{ marginTop: "270px", marginBottom: "-250px" }}>
            <div>
              <div className='d-flex justify-content-center'>
                <img src="iot13.png" alt="STEM Education" style={{ boxShadow: '3px 5px 6px gray', borderRadius: '15px' }} />
              </div>
              <div>
                <h1 className='text-center mt-1'>IOT</h1>
                <p style={{ textAlign: "justify" }}>The Internet of Things (IoT) is transforming our interaction with the world by connecting everyday objects to the internet, enabling data collection and exchange. In K-12 education, IoT opens exciting avenues for students to explore the intersection of technology and real-world applications. Through IoT projects, students learn to design and create smart devices that automate tasks, monitor environments, and enhance efficiency in homes, schools, and cities. This hands-on learning experience equips students with essential skills in programming, sensor technology, and data analysis. IoT education fosters creativity as students innovate solutions for challenges like energy conservation and healthcare monitoring. By understanding IoT principles, K-12 students gain insights into the potential of interconnected systems, preparing them for future careers in engineering, environmental science, urban planning, and beyond.</p>
                <button type="button" className="btn btn-warning">View More</button>
                <button type="button" className="btn btn-danger ms-3">Just Watch</button>
              </div>
            </div>
          </div>
        )}

        {isMedium && (
          <div className="container-sm mb-2" style={{ marginTop: "250px" }}>
            <div>
              <div className='d-flex justify-content-center'>
                <img src="iot13.png" alt="STEM Education" style={{ boxShadow: '3px 5px 6px gray', borderRadius: '15px' }} />
              </div>
              <div>
                <h1 className='text-center mt-1'>IOT</h1>
                <p style={{ textAlign: "justify" }}>The Internet of Things (IoT) is transforming our interaction with the world by connecting everyday objects to the internet, enabling data collection and exchange. In K-12 education, IoT opens exciting avenues for students to explore the intersection of technology and real-world applications. Through IoT projects, students learn to design and create smart devices that automate tasks, monitor environments, and enhance efficiency in homes, schools, and cities. This hands-on learning experience equips students with essential skills in programming, sensor technology, and data analysis. IoT education fosters creativity as students innovate solutions for challenges like energy conservation and healthcare monitoring. By understanding IoT principles, K-12 students gain insights into the potential of interconnected systems, preparing them for future careers in engineering, environmental science, urban planning, and beyond.</p>
                <div>
                  <button type="button" className="btn btn-warning">View More</button>
                  <button type="button" className="btn btn-danger ms-3">Just Watch</button>
                </div>
              </div>
            </div>
          </div>
        )}

        {isLarge && (
          <div className="container-sm" style={{ marginTop: "250px" }}>
            <div>
              <div className='d-flex justify-content-center'>
                <img src="iot13.png" alt="STEM Education" style={{ boxShadow: '3px 5px 6px gray', borderRadius: '15px' }} />
              </div>
              <div>
                <h1 className='text-center mt-1'>IOT</h1>
                <p style={{ textAlign: "justify" }}>The Internet of Things (IoT) is transforming our interaction with the world by connecting everyday objects to the internet, enabling data collection and exchange. In K-12 education, IoT opens exciting avenues for students to explore the intersection of technology and real-world applications. Through IoT projects, students learn to design and create smart devices that automate tasks, monitor environments, and enhance efficiency in homes, schools, and cities. This hands-on learning experience equips students with essential skills in programming, sensor technology, and data analysis. IoT education fosters creativity as students innovate solutions for challenges like energy conservation and healthcare monitoring. By understanding IoT principles, K-12 students gain insights into the potential of interconnected systems, preparing them for future careers in engineering, environmental science, urban planning, and beyond.</p>
                <div>
                  <button type="button" className="btn btn-warning">View More</button>
                  <button type="button" className="btn btn-danger ms-3">Just Watch</button>
                </div>
              </div>
            </div>
          </div>
        )}

        {isXLarge && (
          <div className="container-xxl" style={{ marginTop: "250px" }}>
            <Container className="bg-gradient p-4">
              <Row className="align-items-center">
                <Col md={6}>
                  <img
                    src="iot13.png"
                    alt="STEM Education"
                    className="img-content" style={{ boxShadow: '3px 5px 6px gray', borderRadius: '15px' }}
                  />
                </Col>
                <Col md={6} className="text-black p-4">
                  <h1 className="stmsize">IOT</h1>
                  <span className='text-warning'>*******************************</span>
                  <p className='text-about' style={{ textAlign: "justify" }}>
                    The Internet of Things (IoT) is transforming our interaction with the world by connecting everyday objects to the internet, enabling data collection and exchange. In K-12 education, IoT opens exciting avenues for students to explore the intersection of technology and real-world applications. Through IoT projects, students learn to design and create smart devices that automate tasks, monitor environments, and enhance efficiency in homes, schools, and cities. This hands-on learning experience equips students with essential skills in programming, sensor technology, and data analysis. IoT education fosters creativity as students innovate solutions for challenges like energy conservation and healthcare monitoring. By understanding IoT principles, K-12 students gain insights into the potential of interconnected systems, preparing them for future careers in engineering, environmental science, urban planning, and beyond.
                  </p>
                  <div>
                    <button type="button" className="btn btn-warning">View More</button>
                    <button type="button" className="btn btn-danger ms-3">Just Watch</button>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        )}


        {isXXLarge && (
          <div className="container-xxl">
            <Container className="bg-gradient p-4">
              <Row className="align-items-center">
                <Col md={6}>
                  <img
                    src="iot13.png"
                    alt="STEM Education"
                    className="img-content" style={{ boxShadow: '3px 5px 6px gray', borderRadius: '15px' }}
                  />
                </Col>
                <Col md={6} className="text-black p-4">
                  <h1 className="mb-4 stmsize">IOT</h1>
                  <span className='text-warning'>*********************************</span>
                  <p className='text-about' style={{ textAlign: "justify" }}>
                    The Internet of Things (IoT) is transforming our interaction with the world by connecting everyday objects to the internet, enabling data collection and exchange. In K-12 education, IoT opens exciting avenues for students to explore the intersection of technology and real-world applications. Through IoT projects, students learn to design and create smart devices that automate tasks, monitor environments, and enhance efficiency in homes, schools, and cities. This hands-on learning experience equips students with essential skills in programming, sensor technology, and data analysis. IoT education fosters creativity as students innovate solutions for challenges like energy conservation and healthcare monitoring. By understanding IoT principles, K-12 students gain insights into the potential of interconnected systems, preparing them for future careers in engineering, environmental science, urban planning, and beyond.
                  </p>
                  <div>
                    <button type="button" className="btn btn-warning">View More</button>
                    <button type="button" className="btn btn-danger ms-3">Just Watch</button>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        )}

      </div>
      <Part3 />
      <ServiceSection />
      <Card />
      <StemMindsCourse />
      <Aiml />
      <AvishkaarProduct />
      {/* <Gallery /> */}
      <Mapadd />
      <ChatbotToggle/>
    </div>
  );
}

export default IOTLab;