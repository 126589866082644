import React from 'react';
// import './ourInnovations.css';
// import spiderImage from '../../images/Spider.png';
// import roboticCarImage from '../../images/robotic-car.jpg';
// import roboticsArmImage from '../../images/robotics-rm.jpg';
import { useMediaQuery } from 'react-responsive';


function OurInnovations() {

    const isSmall = useMediaQuery({ query: '(max-width: 576px)' });
    const isMedium = useMediaQuery({ query: '(min-width: 577px) and (max-width: 768px)' });
    const isLarge = useMediaQuery({ query: '(min-width: 769px) and (max-width: 992px)' });
    const isXLarge = useMediaQuery({ query: '(min-width: 993px) and (max-width: 1200px)' });
    const isXXLarge = useMediaQuery({ query: '(min-width: 1201px)' });

    return (
        <div>

            {isSmall && (
                <div className="container-sm">
                    <div>
                        <div>
                            <img src='Spider.png' alt="Robotics Arm" style={{ boxShadow: '3px 5px 6px gray', borderRadius: '15px' }} />
                        </div>
                        <div>
                            <h1 className='text-center mt-1' style={{ fontFamily: "'Dancing Script', cursive", color: "orange" }}>Robotics Arm</h1>
                            <p style={{ textAlign: "justify" }}>
                                Introducing our latest innovation: the Arduino Bluetooth-Controlled Robotic Arm. This state-of-the-art device exemplifies precision and adaptability, allowing users to control it seamlessly via Bluetooth. Designed with six degrees of freedom, it mimics the intricate movements of a human arm, making it ideal for tasks that require high accuracy and flexibility. Whether you're a hobbyist, an educator, or a professional, this robotic arm opens up a world of possibilities for automation and learning. Experience the future of robotics with our cutting-edge technology, designed to inspire and empower creators everywhere.
                            </p>
                        </div>
                    </div>
                    <div>
                        <div>
                            <h1 className='text-center mt-1' style={{ fontFamily: "'Dancing Script', cursive", color: "orange" }}>Robotic Spider</h1>
                            <p style={{ textAlign: "justify" }}>
                                Explore the remarkable 12-DOF, 4-legged Spider Robot, a marvel of modern robotics engineering. This agile and versatile robot is designed with twelve degrees of freedom, allowing it to navigate complex terrains with ease. Its spider-like design provides stability and flexibility, making it perfect for a range of applications, from educational purposes to advanced robotics research. Controlled via a user-friendly interface, this robot showcases the potential of multi-legged movement and coordination. Embrace the future of robotics with this innovative spider robot, and take your projects to new heights of sophistication and functionality.
                            </p>
                        </div>
                        <div>
                            <img src='robotic-car.jpg' alt="Robotic Spider" style={{ boxShadow: '3px 5px 6px gray', borderRadius: '15px' }} />
                        </div>
                    </div>
                    <div className='mt-3'>
                        <div>
                            <img src='robotics-rm.jpg' alt="Robotic Car" style={{ boxShadow: '3px 5px 6px gray', borderRadius: '15px' }} />
                        </div>
                        <div className='mt-4'>
                            <h1 className='text-center mt-1' style={{ fontFamily: "'Dancing Script', cursive", color: "orange" }}>Robotic Car</h1>
                            <p style={{ textAlign: "justify" }}>
                                A robotic car is an autonomous or semi-autonomous vehicle designed to navigate and perform tasks without human intervention. Equipped with sensors, cameras, GPS, and advanced algorithms, these cars can detect and respond to their environment. They are used in various applications, from industrial automation and logistics to research and education. Robotic cars can follow predefined paths, avoid obstacles, and adapt to changing conditions. Their development involves a combination of robotics, computer science, and engineering, focusing on improving efficiency, safety, and functionality. As technology advances, robotic cars are poised to revolutionize transportation and numerous other fields.
                            </p>
                        </div>
                    </div>
                </div>
            )}
            {isMedium && (
                <div className="container-md">
                    <div>
                        <div>
                            <img src='Spider.png' alt="Robotics Arm" style={{ boxShadow: '3px 5px 6px gray', borderRadius: '15px' }} />
                        </div>
                        <div>
                            <h1 className='text-center mt-1' style={{ fontFamily: "'Dancing Script', cursive", color: "orange" }}>Robotics Arm</h1>
                            <p style={{ textAlign: "justify" }}>
                                Introducing our latest innovation: the Arduino Bluetooth-Controlled Robotic Arm. This state-of-the-art device exemplifies precision and adaptability, allowing users to control it seamlessly via Bluetooth. Designed with six degrees of freedom, it mimics the intricate movements of a human arm, making it ideal for tasks that require high accuracy and flexibility. Whether you're a hobbyist, an educator, or a professional, this robotic arm opens up a world of possibilities for automation and learning. Experience the future of robotics with our cutting-edge technology, designed to inspire and empower creators everywhere.
                            </p>
                        </div>
                    </div>
                    <div>
                        <div>
                            <h1 className='text-center mt-1' style={{ fontFamily: "'Dancing Script', cursive", color: "orange" }}>Robotic Spider</h1>
                            <p style={{ textAlign: "justify" }}>
                                Explore the remarkable 12-DOF, 4-legged Spider Robot, a marvel of modern robotics engineering. This agile and versatile robot is designed with twelve degrees of freedom, allowing it to navigate complex terrains with ease. Its spider-like design provides stability and flexibility, making it perfect for a range of applications, from educational purposes to advanced robotics research. Controlled via a user-friendly interface, this robot showcases the potential of multi-legged movement and coordination. Embrace the future of robotics with this innovative spider robot, and take your projects to new heights of sophistication and functionality.
                            </p>
                        </div>
                        <div>
                            <img src='robotic-car.jpg' alt="Robotic Spider" style={{ boxShadow: '3px 5px 6px gray', borderRadius: '15px' }} />
                        </div>
                    </div>
                    <div className='mt-3'>
                        <div>
                            <img src='robotics-rm.jpg' alt="Robotic Car" style={{ boxShadow: '3px 5px 6px gray', borderRadius: '15px' }} />
                        </div>
                        <div className='mt-4'>
                            <h1 className='text-center mt-1' style={{ fontFamily: "'Dancing Script', cursive", color: "orange" }}>Robotic Car</h1>
                            <p style={{ textAlign: "justify" }}>
                                A robotic car is an autonomous or semi-autonomous vehicle designed to navigate and perform tasks without human intervention. Equipped with sensors, cameras, GPS, and advanced algorithms, these cars can detect and respond to their environment. They are used in various applications, from industrial automation and logistics to research and education. Robotic cars can follow predefined paths, avoid obstacles, and adapt to changing conditions. Their development involves a combination of robotics, computer science, and engineering, focusing on improving efficiency, safety, and functionality. As technology advances, robotic cars are poised to revolutionize transportation and numerous other fields.
                            </p>
                        </div>
                    </div>
                </div>
            )}
            {isLarge && (
                <div className="container-lg">
                    <div>
                        <div>
                            <img src='Spider.png' alt="Robotics Arm" style={{ boxShadow: '3px 5px 6px gray', borderRadius: '15px' }} />
                        </div>
                        <div>
                            <h1 className='text-center mt-1' style={{ fontFamily: "'Dancing Script', cursive", color: "orange" }}>Robotics Arm</h1>
                            <p style={{ textAlign: "justify" }}>
                                Introducing our latest innovation: the Arduino Bluetooth-Controlled Robotic Arm. This state-of-the-art device exemplifies precision and adaptability, allowing users to control it seamlessly via Bluetooth. Designed with six degrees of freedom, it mimics the intricate movements of a human arm, making it ideal for tasks that require high accuracy and flexibility. Whether you're a hobbyist, an educator, or a professional, this robotic arm opens up a world of possibilities for automation and learning. Experience the future of robotics with our cutting-edge technology, designed to inspire and empower creators everywhere.
                            </p>
                        </div>
                    </div>
                    <div>
                        <div>
                            <h1 className='text-center mt-1' style={{ fontFamily: "'Dancing Script', cursive", color: "orange" }}>Robotic Spider</h1>
                            <p style={{ textAlign: "justify" }}>
                                Explore the remarkable 12-DOF, 4-legged Spider Robot, a marvel of modern robotics engineering. This agile and versatile robot is designed with twelve degrees of freedom, allowing it to navigate complex terrains with ease. Its spider-like design provides stability and flexibility, making it perfect for a range of applications, from educational purposes to advanced robotics research. Controlled via a user-friendly interface, this robot showcases the potential of multi-legged movement and coordination. Embrace the future of robotics with this innovative spider robot, and take your projects to new heights of sophistication and functionality.
                            </p>
                        </div>
                        <div>
                            <img src='robotic-car.jpg' alt="Robotic Spider" style={{ boxShadow: '3px 5px 6px gray', borderRadius: '15px' }} />
                        </div>
                    </div>
                    <div className='mt-3'>
                        <div>
                            <img src='robotics-rm.jpg' alt="Robotic Car" style={{ boxShadow: '3px 5px 6px gray', borderRadius: '15px' }} />
                        </div>
                        <div className='mt-4'>
                            <h1 className='text-center mt-1' style={{ fontFamily: "'Dancing Script', cursive", color: "orange" }}>Robotic Car</h1>
                            <p style={{ textAlign: "justify" }}>
                                A robotic car is an autonomous or semi-autonomous vehicle designed to navigate and perform tasks without human intervention. Equipped with sensors, cameras, GPS, and advanced algorithms, these cars can detect and respond to their environment. They are used in various applications, from industrial automation and logistics to research and education. Robotic cars can follow predefined paths, avoid obstacles, and adapt to changing conditions. Their development involves a combination of robotics, computer science, and engineering, focusing on improving efficiency, safety, and functionality. As technology advances, robotic cars are poised to revolutionize transportation and numerous other fields.
                            </p>
                        </div>
                    </div>
                </div>
            )}
            {isXLarge && (
                <div className="container-xl" style={{ fontFamily: "'Dancing Script', cursive", color: "orange" }}>
                    <div className='m-3 d-flex w-100'>
                        <div>
                            <img src='Spider.png' alt="Robotics Arm" style={{ width: "600px", boxShadow: '3px 5px 6px gray', borderRadius: '15px' }} />
                        </div>
                        <div className='ms-4'>
                            <h1 className='text-center mt-1' style={{ fontFamily: "'Dancing Script', cursive", color: "orange" }}>Robotics Arm</h1>
                            <p style={{ textAlign: "justify" }}>
                                Introducing our latest innovation: the Arduino Bluetooth-Controlled Robotic Arm. This state-of-the-art device exemplifies precision and adaptability, allowing users to control it seamlessly via Bluetooth. Designed with six degrees of freedom, it mimics the intricate movements of a human arm, making it ideal for tasks that require high accuracy and flexibility. Whether you're a hobbyist, an educator, or a professional, this robotic arm opens up a world of possibilities for automation and learning. Experience the future of robotics with our cutting-edge technology, designed to inspire and empower creators everywhere.
                            </p>
                        </div>
                    </div>

                    <div className='m-3 d-flex w-100 mt-4'>
                        <div className='me-3'>
                            <h1 className='text-center mt-1' style={{ fontFamily: "'Dancing Script', cursive", color: "orange" }}>Robotic Spider</h1>
                            <p style={{ textAlign: "justify" }}>
                                Explore the remarkable 12-DOF, 4-legged Spider Robot, a marvel of modern robotics engineering. This agile and versatile robot is designed with twelve degrees of freedom, allowing it to navigate complex terrains with ease. Its spider-like design provides stability and flexibility, making it perfect for a range of applications, from educational purposes to advanced robotics research. Controlled via a user-friendly interface, this robot showcases the potential of multi-legged movement and coordination. Embrace the future of robotics with this innovative spider robot, and take your projects to new heights of sophistication and functionality.
                            </p>
                        </div>
                        <div>
                            <img src='robotic-car.jpg' alt="Robotic Spider" style={{ width: "600px", }} />
                        </div>
                    </div>

                    <div className='m-3 d-flex w-100 mt-4'>
                        <div>
                            <img className='mt-3' src='robotics-rm.jpg' alt="Robotic Car" style={{ width: "600px", boxShadow: '3px 5px 6px gray', borderRadius: '15px' }} />
                        </div>
                        <div className='ms-4'>
                            <h1 className='text-center mt-1' style={{ fontFamily: "'Dancing Script', cursive", color: "orange" }}>Robotic Car</h1>
                            <p style={{ textAlign: "justify" }}>
                                A robotic car is an autonomous or semi-autonomous vehicle designed to navigate and perform tasks without human intervention. Equipped with sensors, cameras, GPS, and advanced algorithms, these cars can detect and respond to their environment. They are used in various applications, from industrial automation and logistics to research and education. Robotic cars can follow predefined paths, avoid obstacles, and adapt to changing conditions. Their development involves a combination of robotics, computer science, and engineering, focusing on improving efficiency, safety, and functionality. As technology advances, robotic cars are poised to revolutionize transportation and numerous other fields.
                            </p>
                        </div>
                    </div>
                </div>
            )}
            {isXXLarge && (
                <div className="container-xxl" style={{ fontFamily: "'Dancing Script', cursive", }}>
                    <div className='m-3 d-flex w-100'>
                        <div>
                            <img src='Spider.png' alt="Robotics Arm" style={{ width: "600px", boxShadow: '3px 5px 6px gray', borderRadius: '15px' }} />
                        </div>
                        <div className='ms-4'>
                            <h1 className='text-center mt-1' style={{ fontFamily: "'Dancing Script', cursive", color: "orange" }}>Robotics Arm</h1>
                            <p style={{ textAlign: "justify" }}>
                                Introducing our latest innovation: the Arduino Bluetooth-Controlled Robotic Arm. This state-of-the-art device exemplifies precision and adaptability, allowing users to control it seamlessly via Bluetooth. Designed with six degrees of freedom, it mimics the intricate movements of a human arm, making it ideal for tasks that require high accuracy and flexibility. Whether you're a hobbyist, an educator, or a professional, this robotic arm opens up a world of possibilities for automation and learning. Experience the future of robotics with our cutting-edge technology, designed to inspire and empower creators everywhere.
                            </p>
                        </div>
                    </div> 

                    <div className='m-3 d-flex w-100 mt-4'>
                        <div className='me-3'>
                            <h1 className='text-center mt-1' style={{ fontFamily: "'Dancing Script', cursive", color: "orange" }}>Robotic Spider</h1>
                            <p style={{ textAlign: "justify" }}>
                                Explore the remarkable 12-DOF, 4-legged Spider :Robot, a marvel of modern robotics engineering. This agile and versatile robot is designed with twelve degrees of freedom, allowing it to navigate complex terrains with ease. Its spider-like design provides stability and flexibility, making it perfect for a range of applications, from educational purposes to advanced robotics research. Controlled via a user-friendly interface, this robot showcases the potential of multi-legged movement and coordination. Embrace the future of robotics with this innovative spider robot, and take your projects to new heights of sophistication and functionality.
                            </p>
                        </div>
                        <div>
                            <img src='robotic-car.jpg' alt="Robotic Spider" style={{ width: "600px", height: '400px', boxShadow: '3px 5px 6px gray', borderRadius: '15px' }} />
                        </div>
                    </div>

                    <div className='m-3 d-flex w-100 mt-4'>
                        <div>
                            <img className='mt-3' src='robotics-rm.jpg' alt="Robotic Car" style={{ width: "600px", boxShadow: '3px 5px 6px gray', borderRadius: '15px' }} />
                        </div>
                        <div className='ms-4'>
                            <h1 className='text-center mt-1' style={{ fontFamily: "'Dancing Script', cursive", color: "orange" }}>Robotic Car</h1>
                            <p style={{ textAlign: "justify" }}>
                                A robotic car is an autonomous or semi-autonomous vehicle designed to navigate and perform tasks without human intervention. Equipped with sensors, cameras, GPS, and advanced algorithms, these cars can detect and respond to their environment. They are used in various applications, from industrial automation and logistics to research and education. Robotic cars can follow predefined paths, avoid obstacles, and adapt to changing conditions. Their development involves a combination of robotics, computer science, and engineering, focusing on improving efficiency, safety, and functionality. As technology advances, robotic cars are poised to revolutionize transportation and numerous other fields.
                            </p>
                        </div>
                    </div>
                </div>
            )}



        </div>
    )
}

export default OurInnovations;