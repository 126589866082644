import React from "react";
import './carousel.css';

export function Carousel() {
    return (
        <div className="carousel-container">
            <div className="carousel slide carousel-dark" id="banner" data-bs-ride="carousel">
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <img src="img-11.png" className="w-100 d-block" alt="Slide 1" />
                    </div>
                    <div className="carousel-item">
                        <img src="carousel3.jpg" className="w-100 d-block" alt="Slide 2" />
                    </div>
                    <div className="carousel-item">
                        <img src="img-10.png" className="w-100 d-block" alt="Slide 3" />
                    </div>
                    <div className="carousel-item">
                        <img src="img-9.png" className="w-100 d-block" alt="Slide 4" />
                    </div>
                    <div className="carousel-item">
                        <img src="img-12.png" className="w-100 d-block" alt="Slide 5" />
                    </div>
                </div>
                <div className="carousel-controls">
                    <button data-bs-target="#banner" data-bs-slide="prev" className="carousel-control-prev">
                        <span className="carousel-control-prev-icon"></span>
                    </button>
                    <button data-bs-target="#banner" data-bs-slide="next" className="carousel-control-next">
                        <span className="carousel-control-next-icon"></span>
                    </button>
                </div>
            </div>
            {/* Include Bootstrap's JS and jQuery for Carousel functionality */}
            <script src="../node_modules/jquery/dist/jquery.js"></script>
            <script src="../node_modules/bootstrap/dist/js/bootstrap.bundle.js"></script>
        </div>
    );
}
