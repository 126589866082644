import React from "react";
import './Iframe-container.css';

export function Iframe() {
    return (
        <div className="container-fluid">
            <div className="d-flex ms-5 me-5">

                <div className="left">
                    <div>| About SVECW</div>
                    <h1>Empowering women through knowledge and action.</h1>
                    <div className="">
                        SVECW, a higher-education institution set up exclusively for women students, provides an excellent opportunity to them for acquiring specific skills and knowledge, fostering their creativity and nurturing their innovative prowess leading to their intellectual development.
                    </div>
                    <div>
                        <button>Learn more</button>
                    </div>
                </div>

                <div className="right">
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/rQVpjVVY-gU?si=kZwE9eus_aFIwIqC" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
            </div>
        </div>
    )
}