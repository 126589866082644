import React from 'react';
import './CategoryCard.css'; // Import your CSS file

const CategoryCard = ({ title, description, skillsCount, color }) => {
  return (
    <div className="category-card" style={{ borderLeftColor: color }}>
      <div className="category-content">
        <h2 className="category-title" style={{ color }}>{title}</h2>
        <p className="category-description">{description}</p>
      </div>
      <button className="see-all-skills" style={{ backgroundColor: color }}>
        See all {skillsCount} skills ›
      </button>
    </div>
  );
};

export default CategoryCard;
