// src/components/HeroSection.js
import React from 'react';
import './HeroSection.css';

const HeroSection = () => {
  return (
    <section className="benefits-section">
      <h2 style={{ color: "#ffffff", marginLeft: "40px" }}>Benefits of Our STEM Resources & Hands-On Learning</h2>
      <hr />
      {/* <p>Increased EngagemeCentury Skills, Connections to Real-World Problems, .</p> */}
      <div className="benefits-grid">
        <div className="benefit">
          <img src="spl.png" alt="Gear Icon" />
          <h3 style={{ color: "#FFD700" }}>Increased Engagement</h3>
          <p>Providing interactive, immersive experiences to engage students, spark curiosity and enhance the understanding of educational concepts.</p>
        </div>
        <div className="benefit">
          <img src="spl5.png" alt="Graph Icon" />
          <h3 style={{ color: "#FFD700" }}>Development of 21st Century Skills</h3>
          <p>Interactive lesson content promotes critical thinking and creativity while working collaboratively through inquiry and design processes.</p>
        </div>
        <div className="benefit">
          <img src="spl4.png" alt="Microscope Icon" />
          <h3 style={{ color: "#FFD700" }}>New Learning for Existing Subjects</h3>
          <p>Learning existing standards in new, tangible ways increases concept mastery in subjects like science, math, and technology.</p>
        </div>
        <div className="benefit">
          <img src=" spl2.png" alt="Globe Icon" />
          <h3 style={{ color: "#FFD700" }}>Foundations in Coding</h3>
          <p>Gain coding experience to boost computational thinking. The user-friendly, flow-based programming interface empowers beginners to create projects and build coding skills and confidence gradually.</p>
        </div>
        <div className="benefit">
          <img src=" spl3.png" alt="Hourglass Icon" />
          <h3 style={{ color: "#FFD700" }}>Connections to Real-World Problems</h3>
          <p>Real-world problem-solving enhances elementary students’ learning, making it more engaging and relevant and laying a solid foundation for future problem-solving.</p>
        </div>
        <div className="benefit">
          <img src=" spl1.png" alt="Future Icon" />
          <h3 style={{ color: "#FFD700" }}>Building Toward the Future</h3>
          <p>Students connect experiences to future careers while learning technological literacy and interdisciplinary understanding, key competencies requi#FFD700 in an increasingly digital and interconnected world.</p>
        </div>
      </div>
    </section>
  );
};


export default HeroSection;
