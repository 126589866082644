import React from 'react';
import { Link } from 'react-router-dom';
import './top.css';

const Top = () => {
  return (
    <section className='top-section'>
      <header className="row text-center">
        <div className="col-lg-6 text-container">
          <h1 className="logo mb-5 mt-3">STEM World</h1>
          <h1 className='hed1 mb-5'>Robotics & AI Innovation Lab</h1>
          <p className='paragraph mb-5'>
            Robotics & AI Innovation Lab, where education meets innovation with advanced robotics and AI technologies. Our facility offers hands-on learning that sparks curiosity and prepares students for the challenges of tomorrow's technology-driven world.
          </p>
          <p className="lab-pricing-para">
            <Link to="/labPricing">
              <button type="button" className="btn btn-warning lab-pricing-button">Lab Pricing</button>
            </Link>
          </p>
        </div>
        <div className="col-lg-6 image-container">
          <img src="many-image16.jpg.png" alt="ResponsiveImage" />
        </div>
      </header>
    </section>
  );
};

export default Top;

