import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css'; // Create and style this CSS file as needed

function Header() {
  return (
    <div className="banner">
      <Link to="/stem-program" className='text-decoration-none text-black tab tab border rounded  bg-text-success' style={{ background: '	#00008B' }} >
        <div className="tab-icon">
          <img style={{ width: '98px', height: '83px' }} src="contact2.png" alt="" />
        </div>
        <div className='text-white'>About Us</div>
      </Link>
      <Link to="/science-program" className='text-decoration-none text-black tab border rounded  bg-gradient' style={{ background: 'Yellow' }}>
        <div className="tab-icon">
          <img style={{ width: '98px', height: '83px' }} src="robotics2.png" alt="" /> </div>
        <div>Robotics</div>
      </Link>
      <Link to="/atal-tinkering-lab" className='text-decoration-none text-black tab tab border rounded  bg-gradient' style={{ background: 'Teal' }} >
        <div className="tab-icon">
          <img style={{ width: '98px', height: '83px' }} src="coding2.png" alt="" />
        </div>
        <div>Coding</div>
      </Link>
      <Link to="/robotics-lab" className='text-decoration-none text-black tab tab border rounded ' style={{ background: 'Green' }}>
        <div className="tab-icon">
          <img style={{ width: '98px', height: '83px' }} src="iot8.png" alt="" />
        </div>
        <div>IOT</div>
      </Link>
      <Link to="/kreativity-league" className='text-decoration-none text-black tab tab border rounded ' style={{ background: '#ADD8E6' }}>
        <div className="tab-icon">
          <img style={{ width: '98px', height: '83px' }} src="ai5.png" alt="" />
        </div>
        <div>AI</div>
      </Link>
      <Link to="/Printing" className='text-decoration-none text-black tab tab border rounded ' style={{ background: 'Orange' }}>
        <div className="tab-icon">
          <img style={{ width: '98px', height: '83px' }} src="3d-printer3.png" alt="" />
        </div>
        <div>3D Printing </div>
      </Link>
      <Link to="/drones" className='text-decoration-none text-black tab tab border rounded ' style={{ background: ' 	#A9A9A9 ' }}>
        <div className="tab-icon">
          <img style={{ width: '98px', height: '83px' }} src="drone2.png" alt="" />
        </div>
        <div>Drones</div>
      </Link>
      <Link to="/vr" className='text-decoration-none text-black tab tab border rounded  ' style={{ background: 'Purple' }}>
        <div className="tab-icon">
          <img style={{ width: '98px', height: '83px' }} src="vr1.png" alt="" />
        </div>
        <div>VR</div>
      </Link>
      <Link to="/electronics" className='text-decoration-none text-black tab tab border rounded  ' style={{ background: 'Grey' }}>
        <div className="tab-icon">
          <img style={{ width: '98px', height: '83px' }} src="electronics-icons2.png" alt="" />
        </div>
        <div>Electronics</div>
      </Link>
    </div>
  );
}

export default Header;