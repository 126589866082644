import React from 'react';
import './QuerkyTech.css';

const QuarkyTech = () => {
  return (
    <div className="quarky-container">
      <div className='text-section'>
        <h1>OUR R&D</h1>
        <h3>& K-12 CUSTOMIZED LEARNING</h3>
        <p>STEMWORLD develop a comprehensive K-12 curriculum tailored the developmental stages and understanding levels of students integrating play to enhance engagement.</p>
        <p>Our educational platform, STEMVERSE.app, offers divers integration with our products in Robotics, IoT and electronics, connecting them with a concept-based curriculum for K-12 education to enhance learning experiences.</p>
        <p>Our team develop & customize School Labs, DIY kits and IDEA Innovation Studio, which offers AI & Robotics solutions for schools.</p>
        <ul>
          <li>Foundational Stage (5 years)</li>
          <li>Preparatory Stage (3 years)</li>
          <li>Middle Stage (3 years)</li>
          <li>Secondary Stage (4 years)</li>
        </ul>
      </div>
      <img className="quarky-image" src='STEMWORLD-BANNER.png' alt="STEMWORLD Banner" />
    </div>
  );
};

export default QuarkyTech;
