import './new-header.css';
import { Link, useLocation } from 'react-router-dom';
import React, { useState, useRef, useEffect } from 'react';
import { ModalComponent } from './ModalComponent';

export function NewHeader(props) {

    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    const [activeLink, setActiveLink] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [isResourcesOpen, setIsResourcesOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 900);
    const dropdownRef = useRef(null);
    const resourcesDropdownRef = useRef(null);
    const location = useLocation();

    const handleMouseEnter = () => {
        setIsOpen(true);
    };

    const handleMouseLeave = () => {
        setIsOpen(false);
    };

    const handleResourcesMouseEnter = () => {
        setIsResourcesOpen(true);
    };

    const handleResourcesMouseLeave = () => {
        setIsResourcesOpen(false);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
        if (resourcesDropdownRef.current && !resourcesDropdownRef.current.contains(event.target)) {
            setIsResourcesOpen(false);
        }
    };

    const handleClick = () => {
        setIsOpen(!isOpen);
    };

    const handleResourcesClick = () => {
        setIsResourcesOpen(!isResourcesOpen);
    };

    const handleLinkClick = (linkName, path) => {
        setActiveLink(linkName);
        if (isMobile) {
            setIsOpen(false);
            setIsResourcesOpen(false);
            document.getElementById('check').checked = false;
        }
        if (path) {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 900);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, [location]);

    return (
        <>
            <div className='header-top'>
                <div id='social-media'>
                    <a style={{ marginRight: "-17px" }} href='#'><span style={{ color: "white", fontSize: "16px" }} className="bi bi-whatsapp text-#fff"></span></a>
                    <a style={{ marginRight: "-17px" }} href='https://www.facebook.com/profile.php?id=61556281820676'><span style={{ color: "white", fontSize: "16px" }} className="bi bi-facebook text-#fff"></span></a>
                    <a style={{ marginRight: "-17px" }} href='https://x.com/theSTEMWORLD'><span style={{ color: "white" }} className="fa-brands fa-x-twitter text-#fff"></span></a>
                    <a style={{ marginRight: "-17px" }} href='#'><span style={{ color: "white" }} className="bi bi-linkedin text-#fff"></span></a>
                    <a style={{ marginRight: "-17px" }} href='https://www.instagram.com/stemworld_india/'><span style={{ color: "white" }} className="bi bi-instagram text-#fff"></span></a>
                    <a style={{ marginRight: "-17px" }} href='https://www.youtube.com/channel/UC04XepbKk66-4s_0v1cDOPg'><span style={{ color: "white" }} className="bi bi-youtube text-#fff"></span></a>
                </div>

                <div id='contact' style={{ alignItems: "center", marginTop: '7px' }}>
                    <a href='#' style={{ color: "white" }}><span className="bi bi-telephone-fill text-black mb-5"></span>+918121481525 +918121629315</a>
                    <a href='#' style={{ color: "white" }}><span className="bi bi-envelope-fill text-black"></span>stemworld.hyd@gmail.com</a>
                </div>
            </div>

            <div className='newHeader'>
                <div className='header_AAA'>
                    <nav>
                        <input type="checkbox" id="check" />
                        <label htmlFor="check" id="checkbtn"><i className="fa fa-bars"></i></label>

                        <Link to="/">
                            <label className="logo"><img src='favicon.ico.png' id='lobo-circle' alt='logo' /></label>
                        </Link>

                        <ul>
                            <li>
                                <Link
                                    className={`text-black ${activeLink === 'home' ? 'active' : ''}`}
                                    to="/"
                                    onClick={() => handleLinkClick('home', '/')}
                                >
                                    Home
                                </Link>
                            </li>

                            <div className='dropdown'
                                ref={dropdownRef}
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                                onClick={handleClick}
                            >
                                <li className='dropdown-button'>
                                    <Link
                                        className={`text-black ${activeLink === 'service' ? 'active' : ''}`}
                                    // onClick={() => handleLinkClick('service')}
                                    >
                                        Services
                                    </Link>
                                </li>
                                {isOpen && (
                                    <ul className="dropdown-menu">
                                        <li className="dropdown-item">
                                            <Link className="dropdown-link" to="ourPlatform" onClick={() => handleLinkClick('service', 'ourPlatform')}>
                                                Edu Platform
                                            </Link>
                                        </li>
                                        <li className="dropdown-item">
                                            <Link className="dropdown-link" to="ourPrograms" onClick={() => handleLinkClick('service', 'ourPrograms')}>
                                                Programs
                                            </Link>
                                        </li>
                                        <li className="dropdown-item">
                                            <Link className="dropdown-link" to="ourInnovations" onClick={() => handleLinkClick('service', 'ourInnovations')}>
                                                Innovations
                                            </Link>
                                        </li>
                                        <li className="dropdown-item">
                                            <Link className="dropdown-link" to="/blog" onClick={() => handleLinkClick('blog', '/blog')}>
                                                Blogs
                                            </Link>
                                        </li>
                                    </ul>
                                )}
                            </div>

                            <li>
                                <Link
                                    className={`text-black ${activeLink === 'products' ? 'active' : ''}`}
                                    to="/product"
                                    onClick={() => handleLinkClick('products', '/product')}
                                >
                                    Products
                                </Link>
                            </li>
                            <li className='red-colore'>
                                <Link
                                    className={`text-black ${activeLink === 'school-program' ? 'active' : ''}`}
                                    to="/school-program"
                                    onClick={() => handleLinkClick('school-program', '/school-program')}
                                >
                                    School Programs
                                </Link>
                            </li>
                            <li>
                                <Link
                                    className={`text-black ${activeLink === 'gallery' ? 'active' : ''}`}
                                    to="/many-img"
                                    onClick={() => handleLinkClick('gallery', '/many-img')}
                                >
                                    Gallery
                                </Link>
                            </li>

                            <div className='dropdown'
                                ref={resourcesDropdownRef}
                                onMouseEnter={handleResourcesMouseEnter}
                                onMouseLeave={handleResourcesMouseLeave}
                                onClick={handleResourcesClick}
                            >
                                <li className='dropdown-button'>
                                    <Link
                                        className={`text-black ${activeLink === 'blog' ? 'active' : ''}`}
                                    // onClick={() => handleLinkClick('blog')}
                                    >
                                        Curriculum
                                    </Link>
                                </li>
                                {isResourcesOpen && (
                                    <ul className="dropdown-menu">
                                        <li className="dropdown-item">
                                            <Link className="dropdown-link" to="/maths">
                                                <img src="Maths2.png" alt="Maths" className="dropdown-icon" />
                                                Maths
                                            </Link>
                                            <div className="submenu">
                                                <Link to="/ClassesList" className="submenu-link">By class</Link>
                                                <span> • </span>
                                                <Link to="/IXLMaths" className="submenu-link">By topic</Link>
                                            </div>
                                        </li>
                                        <li className="dropdown-item">
                                            <Link className="dropdown-link" to="/national-curriculum">
                                                <img src="national_curriculum_icon.png" alt="National curriculum" className="dropdown-icon" />
                                                National curriculum
                                            </Link>
                                            <div className="submenu">
                                                <Link to="/national-curriculum" className="submenu-link">
                                                    Maths curriculum alignments
                                                </Link>
                                            </div>
                                        </li>
                                    </ul>
                                )}
                            </div>

                            <li>
                                <Link
                                    className={`text-black ${activeLink === 'contact' ? 'active' : ''}`}
                                    to="/contact"
                                    onClick={() => handleLinkClick('contact', '/contact')}
                                >
                                    Contact
                                </Link>
                            </li>
                            <li style={{ marginRight: "-20px" }}>
                                <button className='btn btn-warning' onClick={openModal}>Book a free trial</button>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
            <ModalComponent isOpen={isModalOpen} onClose={closeModal} />
        </>
    );
}
