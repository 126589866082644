import React from 'react';
import Header from '../header/header';
import { Container, Row, Col, Button } from 'react-bootstrap';
import './atal.css'
import { Carousel } from '../carousel/carousel';
// import { NewHeader } from '../new-header/new-header';
// import { NewFooter } from '../new-footer/new-footer';
import { Gallery } from '../gallery/gallery';
import { Part3 } from '../Part_3/Part_3';
import { Link } from 'react-router-dom';
import Aiml from '../ai-ml/ai-ml';
import Mapadd from '../map-add/map-add';
import Card from '../card-img/card-img';
import { StemMindsCourse } from '../StemMindsCourse/StemMindsCourse';
import AvishkaarProduct from '../AvishkaarProduct/AvishkaarProduct';
import ServiceSection from '../OurPlatform/ServiceDetails/serviceSection';
import { useMediaQuery } from 'react-responsive';
import ChatbotToggle from '../Chatbot/chatbot';

function AtalTinkeringLab() {

  const isSmall = useMediaQuery({ query: '(max-width: 576px)' });
  const isMedium = useMediaQuery({ query: '(min-width: 577px) and (max-width: 768px)' });
  const isLarge = useMediaQuery({ query: '(min-width: 769px) and (max-width: 992px)' });
  const isXLarge = useMediaQuery({ query: '(min-width: 993px) and (max-width: 1200px)' });
  const isXXLarge = useMediaQuery({ query: '(min-width: 1201px)' });

  return (
    <div>
      {/* <NewHeader /> */}
      <Carousel />
      <Header />
      <div>

        {isSmall && (
          <div className="container-sm" style={{ marginTop: "270px", marginBottom: "-250px" }}>
            <div>
              <div className='d-flex justify-content-center'>
                <img src="coding23.png" alt="STEM Education" style={{ boxShadow: '3px 5px 6px gray', borderRadius: '15px' }} />
              </div>
              <div>
                <h1 className='text-center mt-1'>Coding</h1>
                <p style={{ textAlign: "justify" }}>Coding empowers K-12 students with essential skills for navigating our increasingly digital world. Beyond computer science, it fosters logical thinking, problem-solving abilities, and creativity. Through coding, students learn to create apps, websites, and games, gaining practical skills applicable across various disciplines. It promotes computational thinking, equipping students with the tools to analyze and solve complex problems systematically. Coding also cultivates resilience and perseverance as students debug their programs and iterate on their designs. By embracing coding early on, students build a foundation for future success in STEM fields and beyond, preparing them to thrive in a technology-driven society.</p>
                <button type="button" className="btn btn-warning">View More</button>
                <button type="button" className="btn btn-danger ms-3">Just Watch</button>
              </div>
            </div>
          </div>
        )}

        {isMedium && (
          <div className="container-sm mb-2" style={{ marginTop: "250px" }}>
            <div>
              <div className='d-flex justify-content-center'>
                <img src="coding23.png" alt="STEM Education" style={{ boxShadow: '3px 5px 6px gray', borderRadius: '15px' }} />
              </div>
              <div>
                <h1 className='text-center mt-1'>Coding</h1>
                <p style={{ textAlign: "justify" }}>Coding empowers K-12 students with essential skills for navigating our increasingly digital world. Beyond computer science, it fosters logical thinking, problem-solving abilities, and creativity. Through coding, students learn to create apps, websites, and games, gaining practical skills applicable across various disciplines. It promotes computational thinking, equipping students with the tools to analyze and solve complex problems systematically. Coding also cultivates resilience and perseverance as students debug their programs and iterate on their designs. By embracing coding early on, students build a foundation for future success in STEM fields and beyond, preparing them to thrive in a technology-driven society.</p>
                <div>
                  <button type="button" className="btn btn-warning">View More</button>
                  <button type="button" className="btn btn-danger ms-3">Just Watch</button>
                </div>
              </div>
            </div>
          </div>
        )}

        {isLarge && (
          <div className="container-sm" style={{ marginTop: "250px" }}>
            <div>
              <div className='d-flex justify-content-center'>
                <img src="coding23.png" alt="STEM Education" style={{ boxShadow: '3px 5px 6px gray', borderRadius: '15px' }} />
              </div>
              <div>
                <h1 className='text-center mt-1'>Coding</h1>
                <p style={{ textAlign: "justify" }}>Coding empowers K-12 students with essential skills for navigating our increasingly digital world. Beyond computer science, it fosters logical thinking, problem-solving abilities, and creativity. Through coding, students learn to create apps, websites, and games, gaining practical skills applicable across various disciplines. It promotes computational thinking, equipping students with the tools to analyze and solve complex problems systematically. Coding also cultivates resilience and perseverance as students debug their programs and iterate on their designs. By embracing coding early on, students build a foundation for future success in STEM fields and beyond, preparing them to thrive in a technology-driven society.</p>
                <div>
                  <button type="button" className="btn btn-warning">View More</button>
                  <button type="button" className="btn btn-danger ms-3">Just Watch</button>
                </div>
              </div>
            </div>
          </div>
        )}

        {isXLarge && (
          <div className="container-xxl" style={{ marginTop: "250px" }}>
            <Container className="bg-gradient p-4">
              <Row className="align-items-center">
                <Col md={6}>
                  <img
                    src="coding23.png"
                    alt="STEM Education"
                    className="img-content" style={{ boxShadow: '3px 5px 6px gray', borderRadius: '15px' }}
                  />
                </Col>
                <Col md={6} className="text-black p-4">
                  <h1 className="stmsize">Coding</h1>
                  <span className='text-warning'>*******************************</span>
                  <p className='text-about' style={{ textAlign: "justify" }}>
                    Coding empowers K-12 students with essential skills for navigating our increasingly digital world. Beyond computer science, it fosters logical thinking, problem-solving abilities, and creativity. Through coding, students learn to create apps, websites, and games, gaining practical skills applicable across various disciplines. It promotes computational thinking, equipping students with the tools to analyze and solve complex problems systematically. Coding also cultivates resilience and perseverance as students debug their programs and iterate on their designs. By embracing coding early on, students build a foundation for future success in STEM fields and beyond, preparing them to thrive in a technology-driven society.
                  </p>
                  <div>
                    <button type="button" className="btn btn-warning">View More</button>
                    <button type="button" className="btn btn-danger ms-3">Just Watch</button>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        )}


        {isXXLarge && (
          <div className="container-xxl">
            <Container className="bg-gradient p-4">
              <Row className="align-items-center">
                <Col md={6}>
                  <img
                    src="coding23.png"
                    alt="STEM Education"
                    className="img-content" style={{ boxShadow: '3px 5px 6px gray', borderRadius: '15px' }}
                  />
                </Col>
                <Col md={6} className="text-black p-4">
                  <h1 className="mb-4 stmsize">Coding</h1>
                  <span className='text-warning'>*****************************</span>
                  <p className='text-about' style={{ textAlign: "justify" }}>
                    Coding empowers K-12 students with essential skills for navigating our increasingly digital world. Beyond computer science, it fosters logical thinking, problem-solving abilities, and creativity. Through coding, students learn to create apps, websites, and games, gaining practical skills applicable across various disciplines. It promotes computational thinking, equipping students with the tools to analyze and solve complex problems systematically. Coding also cultivates resilience and perseverance as students debug their programs and iterate on their designs. By embracing coding early on, students build a foundation for future success in STEM fields and beyond, preparing them to thrive in a technology-driven society.
                  </p>
                  <div>
                    <button type="button" className="btn btn-warning">View More</button>
                    <button type="button" className="btn btn-danger ms-3">Just Watch</button>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        )}

      </div>

      <Part3 />
      <ServiceSection />
      <Card />
      <StemMindsCourse />
      <Aiml />
      <AvishkaarProduct />
      {/* <Gallery /> */}
      <Mapadd />

     <ChatbotToggle/>
    </div>
  );
}

export default AtalTinkeringLab;