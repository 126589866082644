import React from 'react';
import Header from '../header/header';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Carousel } from '../carousel/carousel';
import { Part3 } from '../Part_3/Part_3';
import Aiml from '../ai-ml/ai-ml';
import Mapadd from '../map-add/map-add';
import Card from '../card-img/card-img';
import { StemMindsCourse } from '../StemMindsCourse/StemMindsCourse';
import AvishkaarProduct from '../AvishkaarProduct/AvishkaarProduct';
import ServiceSection from '../OurPlatform/ServiceDetails/serviceSection';
import { useMediaQuery } from 'react-responsive';
import ChatbotToggle from '../Chatbot/chatbot';

function Electronics() {

  const isSmall = useMediaQuery({ query: '(max-width: 576px)' });
  const isMedium = useMediaQuery({ query: '(min-width: 577px) and (max-width: 768px)' });
  const isLarge = useMediaQuery({ query: '(min-width: 769px) and (max-width: 992px)' });
  const isXLarge = useMediaQuery({ query: '(min-width: 993px) and (max-width: 1200px)' });
  const isXXLarge = useMediaQuery({ query: '(min-width: 1201px)' });

  return (
    <div>
      {/* <NewHeader /> */}
      <Carousel />
      <Header />
      <div>

        {isSmall && (
          <div className="container-sm" style={{ marginTop: "270px", marginBottom: "-250px" }}>
            <div>
              <div className='d-flex justify-content-center'>
                <img src="electronic1.png" alt="STEM Education"  style={{ boxShadow: '3px 5px 6px gray', borderRadius: '15px' }} />
              </div>
              <div>
                <h1 className='text-center mt-1'>Electronic</h1>
                <p style={{ textAlign: "justify" }}>Electronics is a foundational component of modern technology, essential for K-12 students interested in STEM fields. It encompasses the study of circuits, components, and systems that power everything from smartphones to spacecraft. Through hands-on activities and projects, students explore the principles of electricity, circuit design, and digital logic. They learn to build and troubleshoot circuits, fostering problem-solving skills and a deeper understanding of everyday gadgets. Electronics education encourages innovation as students create their own electronic devices and prototypes, preparing them for future careers in engineering, robotics, and technology development. By mastering electronics, students gain practical skills that are increasingly valuable in our interconnected world.</p>
                <button type="button" className="btn btn-warning">View More</button>
                <button type="button" className="btn btn-danger ms-3">Just Watch</button>
              </div>
            </div>
          </div>
        )}

        {isMedium && (
          <div className="container-sm mb-2" style={{ marginTop: "250px" }}>
            <div>
              <div className='d-flex justify-content-center'>
                <img src="electronic1.png" alt="STEM Education" style={{ boxShadow: '3px 5px 6px gray', borderRadius: '15px' }}  />
              </div>
              <div>
                <h1 className='text-center mt-1'>Electronic</h1>
                <p style={{ textAlign: "justify" }}>Electronics is a foundational component of modern technology, essential for K-12 students interested in STEM fields. It encompasses the study of circuits, components, and systems that power everything from smartphones to spacecraft. Through hands-on activities and projects, students explore the principles of electricity, circuit design, and digital logic. They learn to build and troubleshoot circuits, fostering problem-solving skills and a deeper understanding of everyday gadgets. Electronics education encourages innovation as students create their own electronic devices and prototypes, preparing them for future careers in engineering, robotics, and technology development. By mastering electronics, students gain practical skills that are increasingly valuable in our interconnected world.</p>
                <div>
                  <button type="button" className="btn btn-warning">View More</button>
                  <button type="button" className="btn btn-danger ms-3">Just Watch</button>
                </div>
              </div>
            </div>
          </div>
        )}

        {isLarge && (
          <div className="container-sm" style={{ marginTop: "250px" }}>
            <div>
              <div className='d-flex justify-content-center'>
                <img src="electronic1.png" alt="STEM Education" style={{ boxShadow: '3px 5px 6px gray', borderRadius: '15px' }}  />
              </div>
              <div>
                <h1 className='text-center mt-1'>Electronic</h1>
                <p style={{ textAlign: "justify" }}>Electronics is a foundational component of modern technology, essential for K-12 students interested in STEM fields. It encompasses the study of circuits, components, and systems that power everything from smartphones to spacecraft. Through hands-on activities and projects, students explore the principles of electricity, circuit design, and digital logic. They learn to build and troubleshoot circuits, fostering problem-solving skills and a deeper understanding of everyday gadgets. Electronics education encourages innovation as students create their own electronic devices and prototypes, preparing them for future careers in engineering, robotics, and technology development. By mastering electronics, students gain practical skills that are increasingly valuable in our interconnected world.</p>
                <div>
                  <button type="button" className="btn btn-warning">View More</button>
                  <button type="button" className="btn btn-danger ms-3">Just Watch</button>
                </div>
              </div>
            </div>
          </div>
        )}

        {isXLarge && (
          <div className="container-xxl" style={{ marginTop: "250px" }}>
            <Container className="bg-gradient p-4">
              <Row className="align-items-center">
                <Col md={6}>
                  <img
                    src="electronic1.png"
                    alt="STEM Education"
                    className="img-content" style={{ boxShadow: '3px 5px 6px gray', borderRadius: '15px' }} 
                  />
                </Col>
                <Col md={6} className="text-black p-4">
                  <h1 className="stmsize">Electronic</h1>
                  <span className='text-warning'>*******************************</span>
                  <p className='text-about' style={{ textAlign: "justify" }}>
                    Electronics is a foundational component of modern technology, essential for K-12 students interested in STEM fields. It encompasses the study of circuits, components, and systems that power everything from smartphones to spacecraft. Through hands-on activities and projects, students explore the principles of electricity, circuit design, and digital logic. They learn to build and troubleshoot circuits, fostering problem-solving skills and a deeper understanding of everyday gadgets. Electronics education encourages innovation as students create their own electronic devices and prototypes, preparing them for future careers in engineering, robotics, and technology development. By mastering electronics, students gain practical skills that are increasingly valuable in our interconnected world.
                  </p>
                  <div>
                    <button type="button" className="btn btn-warning">View More</button>
                    <button type="button" className="btn btn-danger ms-3">Just Watch</button>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        )}


        {isXXLarge && (
          <div className="container-xxl">
            <Container className="bg-gradient p-4">
              <Row className="align-items-center">
                <Col md={6}>
                  <img
                    src="electronic1.png"
                    alt="STEM Education"
                    className="img-content" style={{ boxShadow: '3px 5px 6px gray', borderRadius: '15px' }} 
                  />
                </Col>
                <Col md={6} className="text-black p-4">
                  <h1 className="mb-4 stmsize">Electronic</h1>
                  <span className='text-warning'>****************************</span>
                  <p className='text-about' style={{ textAlign: "justify" }}>
                    Electronics is a foundational component of modern technology, essential for K-12 students interested in STEM fields. It encompasses the study of circuits, components, and systems that power everything from smartphones to spacecraft. Through hands-on activities and projects, students explore the principles of electricity, circuit design, and digital logic. They learn to build and troubleshoot circuits, fostering problem-solving skills and a deeper understanding of everyday gadgets. Electronics education encourages innovation as students create their own electronic devices and prototypes, preparing them for future careers in engineering, robotics, and technology development. By mastering electronics, students gain practical skills that are increasingly valuable in our interconnected world.
                  </p>
                  <div>
                    <button type="button" className="btn btn-warning">View More</button>
                    <button type="button" className="btn btn-danger ms-3">Just Watch</button>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        )}

      </div>
      <Part3 />
      <ServiceSection />
      <Card />
      <StemMindsCourse />
      <Aiml />
      <AvishkaarProduct />
      {/* <Gallery /> */}
      <Mapadd />
      <ChatbotToggle/>
      {/* <ComStem/> */}
    </div>
  );
}

export default Electronics;