import React from 'react';
import CategoryCard from '../CategoryCard/CategoryCard';
// import TopSection from '../TopSection/TopSection';
const categories = [
    {
        title: 'Addition',
        description: 'Add with pictures - sums up to 10 | Addition sentences using number lines - sums up to 10 | Addition facts - sums up to 10 | Addition word problems - sums up to 18',
        skillsCount: 207,
        color: '#0000FF',
    },
    {
        title: 'Algebra',
        description: 'Add and subtract polynomials using algebra tiles | Multiply two polynomials using algebra tiles | Graph solutions to one-step linear inequalities | Graph solutions to two-step linear inequalities',
        skillsCount: 403,
        color: '#A020F0',
    },
    {
        title: 'Calculus',
        description: 'Find one-sided limits using graphs | Determine continuity on an interval using graphs | Determine the continuity of a piecewise function at a point | Chain rule',
        skillsCount: 88,
        color: 'green',
    },
    {
        title: 'Comparing',
        description: 'Fewer, more and same | Which number is greatest/least? | Compare numbers up to five | Compare numbers up to ten',
        skillsCount: 101,
        color: '#A020F0',
    },
    {
        title: 'Counting',
        description: 'Includes: Count dots - up to 3|Count forward and backward - up to 10|Count to 20|Even or odd numbers on number lines|Counting patterns - up to 100',
        skillsCount: 112,
        color: '#f24422',
    },
    {
        title: 'Decimals',
        description: 'Includes: Convert fractions to decimals|Convert decimals to fractions|Maps with decimal distances|Estimate sums, differences and products of decimals',
        skillsCount: 79,
        color: '#587bf8',
    },
    {
        title: 'Division',
        description: 'Includes: Division facts to 10|Complete the division table|Interpret remainders|Divisibility rules|Divide money amounts: word problems',
        skillsCount: 97,
        color: '#f48521',
    },
    {
        title: 'Estimation',
        description: 'Includes: Estimate to the nearest ten|Estimate sums: word problems|Estimate products: word problems|Estimate sums, differences, products and quotients: word problems',
        skillsCount: 61,
        color: '#15a085',
    },
    {
        title: 'Exponents, roots and logarithms',
        description: 'Includes: Multiplication with exponents|Division with exponents|Positive and negative square roots|Cube roots of perfect cubes|Estimate cube roots',
        skillsCount: 86,
        color: '#3aa9fc',
    },
    {
        title: 'Fractions',
        description: 'Includes: Simple fractions: what fraction does the shape show?|Simple fractions: parts of a group|Fractions of a number|Fractions of a number: word problems|Simplify rational expressions',
        skillsCount: 124,
        color: '#fece36',
    },
    {
        title: 'Functions and equations',
        description: 'Includes: Solve one-step equations|Find the constant of proportionality from a graph|Interpret graphs of proportional relationships|Identify linear equations|Identify functions: vertical line test',
        skillsCount: 282,
        color: '#2cce8c',
    },
    {
        title: 'Geometry',
        description: 'Includes: SSS, SAS and ASA Theorems|Arc measure and arc length|Angles in inscribed right triangles|Pythagoras Inequality Theorems|Tangent lines',
        skillsCount: 514,
        color: '#f96855',
    },
    {
        title: 'Graphs',
        description: 'Includes: Create line plots|Sort shapes into a Venn diagram|Frequency charts|Interpret stem-and-leaf plots|Create histograms',
        skillsCount: 188,
        color: '#a95edb',
    },
    {
        title: 'Integers',
        description: 'Includes: Integers on number lines|Subtract integers|Add and subtract integers: find the sign|Integer multiplication and division rules',
        skillsCount: 50,
        color: '#2cce8c',
    },
    {
        title: 'Logic and reasoning',
        description: 'Includes: Largest/smallest number possible|Find two numbers based on sum and difference|Solve word problems using guess-and-check|Truth values|Converses, inverses and contrapositives',
        skillsCount: 46,
        color: '#0374d3',
    },
    {
        title: 'Measurement',
        description: 'Includes: Compare and convert metric units of length|Conversion tables|Read a thermometer|Metric mixed units|Convert between cubic metres and litres',
        skillsCount: 70,
        color: '#0374d3',
    },
    {
        title: 'Mixed operations',
        description: 'Includes: Which sign (+ or -) makes the number sentence true? - up to 100|Add, subtract, multiply and divide|Addition, subtraction, multiplication and division word problems',
        skillsCount: 166,
        color: '#2acfd3',
    },
    {
        title: 'Money and consumer maths',
        description: 'Includes: Least number of coins|Add and subtract money amounts|Compare money amounts|Find the number of each type of coin|Simple interest',
        skillsCount: 98,
        color: '#7a57ce',
    },
    {
        title: 'Multiplication',
        description: 'Includes: Multiplication facts up to 10: select the missing factors|Multiplication word problems|Multiply 1-digit numbers by larger numbers|Choose numbers with a particular product',
        skillsCount: 139,
        color: '#8bc427',
    },
    {
        title: 'Number theory',
        description: 'Includes: HCF and LCM: word problems|Prime or composite|Prime factorisation|Identify rational and irrational numbers|Classify numbers',
        skillsCount: 48,
        color: '#f24422',
    },
    {
        title: 'Patterns',
        description: 'Includes: Make a pattern|Repeating patterns|Complete an increasing number pattern|Number patterns: mixed review|Evaluate variable expressions for number sequences',
        skillsCount: 119,
        color: '#587bf8',
    },
    {
        title: 'Percents',
        description: 'Includes: Estimate percents of numbers|Find the percent: discount and mark-up|Sale prices: find the original price|Multi-step problems with percents|Compound interest',
        skillsCount: 54,
        color: '#f48521',
    },
    {
        title: 'Place values',
        description: 'Includes: Place value models up to hundreds|Place value names up to thousands|Convert between place values|Place value word problems|Convert between place values',
        skillsCount: 38,
        color: '#15a085',
    }
];

const IXLMaths = () => {
    return (
        <div className="ixl-maths">
            {/* <TopSection/> */}
            <h1>STEM Maths</h1>
            <p>On IXL, maths is more than just numbers. With unlimited questions, engaging item types, and real-world scenarios, IXL helps learners experience maths at its most mesmerizing!</p>
            <div className="categories-container">
                {categories.map((category, index) => (
                    <CategoryCard
                        key={index}
                        title={category.title}
                        description={category.description}
                        skillsCount={category.skillsCount}
                        color={category.color}
                    />
                ))}
            </div>
        </div>
    );
};

export default IXLMaths;
