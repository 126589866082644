// ServiceSection.jsx
import React from 'react';
import './serviceSection.css'; // Correct casing

const services = [
  { title: 'AI & ROBOTICS LAB', description: 'Establish advanced laboratory equipped with cutting-edge STEM based software and hardware, focusing on training students with a concept-based curriculum', icon: 'ai-robotics2.png' },
  { title: 'CODING PLATFORM', description: 'latform with educational materials and activities that align with curriculum standards, making it easy for students to continue their education and explore STEM projects.', icon: 'coding-plat2.png' },
  { title: 'SCHOOL INNOVATION PROGRAM', description: 'Comprehensive initiative designed to revolutionize the learning experience by integrating advanced STEM education into the school curriculum. Create a Club, fostering creativity, critical thinking, ', icon: 'school-innovation2.png' },
  { title: 'TEACHERS TRANING', description: 'Teachers Training program is designed to equip educators with the skills, knowledge, technology, and concept-based STEM curriculum. Conduct workshops, events, Hackathons and competitions.', icon: 'teachers-tra2.png' },
  { title: 'DIY KITS, HOME EDU-KITS', description: 'DIY Kits provide hands-on learning experiences for students. Home EDU-Kits are designed for at-home learning with educational materials.', icon: 'diy-kits2.png' },
  { title: 'SCHOOL ERP SOLUTIONS', description: 'Designed to streamline and automate various administrative and academic processes within educational institutions.', icon: 'school-erp2.png' },
];

function ServiceSection() {
  return (
    <div className="service-details">
      <h2 className='service-name' style={{ color: "orange", fontSize: '40px' }}>SERVICES</h2>
      {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam ultrices sapien vel quam luctus pulvinar.</p> */}
      <div className="service-cards">
        {services.map((service, index) => (
          <div className="service-card" key={index}>
            <img src={service.icon} alt={`${service.title} icon`} />
            <h3>{service.title}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ServiceSection;
