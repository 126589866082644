// ModalComponent.jsx
import { useFormik, Formik, Form, Field, ErrorMessage } from "formik";
import axios from "axios";
import * as yup from "yup";

import React from 'react';
import './modal-component.css';

export function ModalComponent({ isOpen, onClose }) {
    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <button className="modal-close-button" onClick={onClose}>
                    &times;
                </button>
                <div className="modal-body">
                    <div className="modal-image">
                        <img src="trophy.png" alt="Trophy" />
                    </div>
                    <div className="modal-form">
                        <h2>Try for free, <span className="highlight">register now</span></h2>
                        <Formik
                            initialValues={{
                                Parent: "",
                                Kid: "",
                                Phone: "",
                                Email: ""
                            }}

                            validationSchema={
                                yup.object({
                                    Parent: yup.string().required("Parent Name Required"),
                                    Kid: yup.string().required("Kid Required"),
                                    Phone: yup.string().required("Phone Required").matches(/\+91\d{10}/, "Invalid Mobile +91 and 10 digits"),
                                    Email: yup.string().required("Email Required").email("Invalid Email")
                                })
                            }

                            onSubmit={
                                (values) => {
                                    axios({
                                        method: "post",
                                        url: "http://127.0.0.1:5000/registertrial",
                                        data: values
                                    })
                                        .then(() => {
                                            alert("Register Successfully..");
                                        })
                                }
                            }
                        >
                            {
                                <Form>
                                    <Field type="text" name='Parent' placeholder="Parent's name" />
                                    <ErrorMessage name="Parent" />

                                    <Field type="text" name='Kid' placeholder="Kid's name" />
                                    <ErrorMessage name="Kid" />

                                    <Field type="text" name='Phone' placeholder="Phone Number" />
                                    <ErrorMessage name="Phone" />

                                    <Field type="text" name='Email' placeholder="Email" />
                                    <ErrorMessage name="Email" />

                                    <button type="submit" className="modal-submit-button">Book a free trial</button>

                                </Form>
                            }
                        </Formik>

                    </div>
                </div>
            </div>
        </div>
    );
}
