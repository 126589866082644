import React from 'react'
import './education.css';

function Education() {
  return (
    <div className='education'>
      <h1>Upgrading Education with Forward-Thinking Initiatives</h1>
      <p>STEMpedia has tailored its products, curricula, and services to meet the needs of the general public through its innovative initiatives in schools, government, corporate social responsibility, and impact programs. These programs are designed to achieve desired outcomes and adhere to the educational standards established by modern policies around the world.</p>


      <div className='education_education'>
        <div className='education_details'>
          <div className='education_image'>
            <img src='education1.png' alt='image' />
          </div>
          <div className='education_content'>
            <h1>AI & Robotics Lab for Schools</h1>
            <h5>500+ AI and Robotics Labs Established</h5>
            <p>STEMpedia AI and Robotics Lab are upgrading ICT labs in schools by integrating modern ICT, coding, AI, and robotics education into the curriculum for classes 3-12. Through hands-on learning with PictoBlox and Quarky, students are able to gain valuable skills that can help prepare them for the future.</p>
            <div className='education_button'>
              <button className='btn btn-danger'>Watch Video</button>
              <button className='btn btn-info ms-2'>Explore More</button>
            </div>
          </div>
        </div>
        <div className='education_details' style={{marginLeft:"-100px"}}>
          <div className='education_content'>
            <h1>AI & Robotics Lab for Schools</h1>
            <h5>500+ AI and Robotics Labs Established</h5>
            <p>STEMpedia AI and Robotics Lab are upgrading ICT labs in schools by integrating modern ICT, coding, AI, and robotics education into the curriculum for classes 3-12. Through hands-on learning with PictoBlox and Quarky, students are able to gain valuable skills that can help prepare them for the future.</p>
            <div className='education_button'>
              <button className='btn btn-danger'>Watch Video</button>
              <button className='btn btn-info ms-2'>Explore More</button>
            </div>
          </div>
          <div className='education_image' style={{position:"relative",left:"-85px"}} >
            <img src='edu2.png' alt='image' />
          </div>
        </div>
        <div className='education_details'>
          <div className='education_image'>
            <img src='education1.png' alt='image' />
          </div>
          <div className='education_content'>
            <h1>AI & Robotics Lab for Schools</h1>
            <h5>500+ AI and Robotics Labs Established</h5>
            <p>STEMpedia AI and Robotics Lab are upgrading ICT labs in schools by integrating modern ICT, coding, AI, and robotics education into the curriculum for classes 3-12. Through hands-on learning with PictoBlox and Quarky, students are able to gain valuable skills that can help prepare them for the future.</p>
            <div className='education_button'>
              <button className='btn btn-danger'>Watch Video</button>
              <button className='btn btn-info ms-2'>Explore More</button>
            </div>
          </div>
        </div>
        <div className='education_details' style={{marginLeft:"-100px"}}>
          <div className='education_content'>
            <h1>AI & Robotics Lab for Schools</h1>
            <h5>500+ AI and Robotics Labs Established</h5>
            <p>STEMpedia AI and Robotics Lab are upgrading ICT labs in schools by integrating modern ICT, coding, AI, and robotics education into the curriculum for classes 3-12. Through hands-on learning with PictoBlox and Quarky, students are able to gain valuable skills that can help prepare them for the future.</p>
            <div className='education_button'>
              <button className='btn btn-danger'>Watch Video</button>
              <button className='btn btn-info ms-2'>Explore More</button>
            </div>
          </div>
          <div className='education_image' style={{position:"relative",left:"-85px"}} >
            <img src='edu2.png' alt='image' />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Education
