import './StemCom.css';

export function StemCom() 
{
    return (
        <div>
            <div id='main'>
                <h1>Empowering Kids with the Right Future Skills</h1>
                <p>With the hands-on approach to AI, Coding, Robotics, and STEM, we cultivate a generation of innovators by providing the 21st-century skills to unleash the true potential of young minds. </p>
                <div id="card">
                    <div className="card">
                        <img className="card-img-top" src="ai2.png" />
                        <div className="card-header">
                            <p>Coding - Graphical & Python</p>
                        </div>
                    </div>
                    <div className="card">
                        <img className="card-img-top" src="ai2.png" />
                        <div className="card-header">
                            <p>Artificial Intelligence</p>
                        </div>
                    </div>
                    <div className="card">
                        <img className="card-img-top" src="ai2.png" />
                        <div className="card-header">
                            <p>Machine Learning</p>
                        </div>
                    </div>
                    <div className="card">
                        <img className="card-img-top" src="ai2.png" />
                        <div className="card-header">
                            <p>Robotics</p>
                        </div>
                    </div>
                    <div className="card">
                        <img className="card-img-top" src="ai2.png" />
                        <div className="card-header">
                            <p>AR & VR Tech</p>
                        </div>
                    </div>
                    <div className="card">
                        <img className="card-img-top" src="ai2.png" />
                        <div className="card-header">
                            <p>Internet of Things (IoT)</p>
                        </div>
                    </div>
                    <div className="card">
                        <img className="card-img-top" src="ai2.png" />
                        <div className="card-header">
                            <p>Biomimetic Robot</p>
                        </div>
                    </div>
                    <div className="card">
                        <img className="card-img-top" src="ai2.png" />
                        <div className="card-header">
                            <p>Advanced Robotics</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}