import React from "react";
import './Part_3.css';

export function Part3() {
    return (
        <div className='container-3'>
            <h1 className='approach-title'>Our Approach</h1>
            <div className='approach-items'>
                <div className='approach-item'>
                    <div className='image-circle learn-img'></div>
                    <h2>Learn</h2>
                    <p>Discover the wonders of STEM with comprehensive and engaging lessons.</p>
                </div>
                <div className='approach-item'>
                    <div className='image-circle experiment-img'></div>
                    <h2>Experiment</h2>
                    <p>Dive into hands-on activities and experiments to bring concepts to life.</p>
                </div>
                <div className='approach-item'>
                    <div className='image-circle innovate-img'></div>
                    <h2>Innovate</h2>
                    <p>Turn your ideas into reality and push the boundaries of what's possible.</p>
                </div>
            </div>
        </div>
    );
}
