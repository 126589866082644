import React from 'react';
import Header from '../header/header';
import { Container, Row, Col, Button } from 'react-bootstrap';
import './kreativity.css'
import { Carousel } from '../carousel/carousel';
import { Part3 } from '../Part_3/Part_3';
import Mapadd from '../map-add/map-add';
import Card from '../card-img/card-img';
import { StemMindsCourse } from '../StemMindsCourse/StemMindsCourse';
import AvishkaarProduct from '../AvishkaarProduct/AvishkaarProduct';
import Aiml from '../ai-ml/ai-ml';
import ServiceSection from '../OurPlatform/ServiceDetails/serviceSection';
import { useMediaQuery } from 'react-responsive';
import ChatbotToggle from '../Chatbot/chatbot';

function KreativityLeague() {

  const isSmall = useMediaQuery({ query: '(max-width: 576px)' });
  const isMedium = useMediaQuery({ query: '(min-width: 577px) and (max-width: 768px)' });
  const isLarge = useMediaQuery({ query: '(min-width: 769px) and (max-width: 992px)' });
  const isXLarge = useMediaQuery({ query: '(min-width: 993px) and (max-width: 1200px)' });
  const isXXLarge = useMediaQuery({ query: '(min-width: 1201px)' });

  return (
    <div>
      <Carousel />
      <Header />
      <div>

        {isSmall && (
          <div className="container-sm" style={{ marginTop: "270px", marginBottom: "-250px" }}>
            <div>
              <div className='d-flex justify-content-center'>
                <img src="ai6.png" alt="STEM Education" style={{ boxShadow: '3px 5px 6px gray', borderRadius: '15px' }} />
              </div>
              <div>
                <h1 className='text-center mt-1'>Artificial Intelligence</h1>
                <p style={{ textAlign: "justify" }}>Artificial Intelligence (AI) is revolutionizing how we interact with technology and comprehend our world. In K-12 education, AI offers students a glimpse into the future of innovation and problem-solving. Through AI, students learn how machines can process data, make decisions, and understand human language, fostering critical thinking by examining ethical considerations and societal impacts. AI education introduces students to concepts like machine learning and robotics, preparing them for careers in diverse fields, from healthcare to entertainment. Engaging with AI not only equips students with technical skills but also ignites their curiosity to explore new possibilities and shape the future of technology.</p>
                <button type="button" className="btn btn-warning">View More</button>
                <button type="button" className="btn btn-danger ms-3">Just Watch</button>
              </div>
            </div>
          </div>
        )}

        {isMedium && (
          <div className="container-sm mb-2" style={{ marginTop: "250px" }}>
            <div>
              <div className='d-flex justify-content-center'>
                <img src="ai6.png" alt="STEM Education" style={{ boxShadow: '3px 5px 6px gray', borderRadius: '15px' }} />
              </div>
              <div>
                <h1 className='text-center mt-1'>Artificial Intelligence</h1>
                <p style={{ textAlign: "justify" }}>Artificial Intelligence (AI) is revolutionizing how we interact with technology and comprehend our world. In K-12 education, AI offers students a glimpse into the future of innovation and problem-solving. Through AI, students learn how machines can process data, make decisions, and understand human language, fostering critical thinking by examining ethical considerations and societal impacts. AI education introduces students to concepts like machine learning and robotics, preparing them for careers in diverse fields, from healthcare to entertainment. Engaging with AI not only equips students with technical skills but also ignites their curiosity to explore new possibilities and shape the future of technology.</p>
                <div>
                  <button type="button" className="btn btn-warning">View More</button>
                  <button type="button" className="btn btn-danger ms-3">Just Watch</button>
                </div>
              </div>
            </div>
          </div>
        )}

        {isLarge && (
          <div className="container-sm" style={{ marginTop: "250px" }}>
            <div>
              <div className='d-flex justify-content-center'>
                <img src="ai6.png" alt="STEM Education" style={{ boxShadow: '3px 5px 6px gray', borderRadius: '15px' }} />
              </div>
              <div>
                <h1 className='text-center mt-1'>Artificial Intelligence</h1>
                <p style={{ textAlign: "justify" }}>Artificial Intelligence (AI) is revolutionizing how we interact with technology and comprehend our world. In K-12 education, AI offers students a glimpse into the future of innovation and problem-solving. Through AI, students learn how machines can process data, make decisions, and understand human language, fostering critical thinking by examining ethical considerations and societal impacts. AI education introduces students to concepts like machine learning and robotics, preparing them for careers in diverse fields, from healthcare to entertainment. Engaging with AI not only equips students with technical skills but also ignites their curiosity to explore new possibilities and shape the future of technology.</p>
                <div>
                  <button type="button" className="btn btn-warning">View More</button>
                  <button type="button" className="btn btn-danger ms-3">Just Watch</button>
                </div>
              </div>
            </div>
          </div>
        )}

        {isXLarge && (
          <div className="container-xxl" style={{ marginTop: "250px" }}>
            <Container className="bg-gradient p-4">
              <Row className="align-items-center">
                <Col md={6}>
                  <img
                    src="ai6.png"
                    alt="STEM Education"
                    className="img-content" style={{ boxShadow: '3px 5px 6px gray', borderRadius: '15px' }}
                  />
                </Col>
                <Col md={6} className="text-black p-4">
                  <h1 className="stmsize">Artificial Intelligence</h1>
                  <span className='text-warning'>******************************</span>
                  <p className='text-about' style={{ textAlign: "justify" }}>
                    Artificial Intelligence (AI) is revolutionizing how we interact with technology and comprehend our world. In K-12 education, AI offers students a glimpse into the future of innovation and problem-solving. Through AI, students learn how machines can process data, make decisions, and understand human language, fostering critical thinking by examining ethical considerations and societal impacts. AI education introduces students to concepts like machine learning and robotics, preparing them for careers in diverse fields, from healthcare to entertainment. Engaging with AI not only equips students with technical skills but also ignites their curiosity to explore new possibilities and shape the future of technology.
                  </p>
                  <div>
                    <button type="button" className="btn btn-warning">View More</button>
                    <button type="button" className="btn btn-danger ms-3">Just Watch</button>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        )}


        {isXXLarge && (
          <div className="container-xxl">
            <Container className="bg-gradient p-4">
              <Row className="align-items-center">
                <Col md={6}>
                  <img
                    src="ai6.png"
                    alt="STEM Education"
                    className="img-content" style={{ boxShadow: '3px 5px 6px gray', borderRadius: '15px' }}
                  />
                </Col>
                <Col md={6} className="text-black p-4">
                  <h1 className="mb-4 stmsize">Artificial Intelligence</h1>
                  <span className='text-warning'>*******************************</span>
                  <p className='text-about' style={{ textAlign: "justify" }}>
                    Artificial Intelligence (AI) is revolutionizing how we interact with technology and comprehend our world. In K-12 education, AI offers students a glimpse into the future of innovation and problem-solving. Through AI, students learn how machines can process data, make decisions, and understand human language, fostering critical thinking by examining ethical considerations and societal impacts. AI education introduces students to concepts like machine learning and robotics, preparing them for careers in diverse fields, from healthcare to entertainment. Engaging with AI not only equips students with technical skills but also ignites their curiosity to explore new possibilities and shape the future of technology.
                  </p>
                  <div>
                    <button type="button" className="btn btn-warning">View More</button>
                    <button type="button" className="btn btn-danger ms-3">Just Watch</button>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        )}

      </div>
      <Part3 />
      <ServiceSection />
      <Card />
      <StemMindsCourse />
      <Aiml />
      <AvishkaarProduct />
      <Mapadd />
      <ChatbotToggle/>
      <div className="footer">
      </div>
    </div>
  );
}

export default KreativityLeague;