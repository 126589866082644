import React from 'react'
import Header_ixl from '../Header_IXL/header_ixl'
// import './class-2.css';

function Class5() {
    return (
        <div>

            <div className="container">

                <Header_ixl />

                <div className="main-content">
                    <div className="left-content">
                        <h3>India</h3>
                        <h4><span className=''></span> Skills available for India class II maths curriculum</h4>
                        <div className="description">
                            Objectives are in black and IXL maths skills are in dark green. Hold your mouse over the name of a skill to view a sample question. Click on the name of a skill to practise that skill.
                        </div>
                    </div>

                    <div className="right-content">
                        <img
                            src="https://upload.wikimedia.org/wikipedia/en/4/41/Flag_of_India.svg"
                            alt="India Flag"
                            className="india-flag"
                        />
                    </div>
                </div>

                <div style={{ display: "flex", width: "100%", marginTop: "20px", padding: "15px" }}>
                    <div style={{ width: "75%", background: "#f65314", padding: "20px" }}>
                        <h6>Showing alignment for:</h6>
                        <p>National Council of Education Research and Training Syllabus</p>
                    </div>
                    <div style={{ width: "25%", background: "gray", padding: "20px" }}>
                        <h6>Actions</h6>
                        <p>Print curriculum</p>
                    </div>
                </div>

            </div>


            <div style={{ marginLeft: "110px" }}>
                <ul style={{listStyle:"none"}}>
                    <li><h5 style={{color:"red"}}>2.G Geometry</h5>
                        <ul style={{listStyle:"none"}}>
                            <li><h6>2.G.A Shapes & Spatial Understanding 3-D and 2-D Shapes</h6>
                                <ul>
                                    <li><h6>2.G.A.1 Observes objects in the environment and gets a qualitative feel for their geometrical attributes.</h6>
                                        <ul>
                                            <li>Shapes of everyday objects I (I-N.9)</li>
                                            <li>Shapes of everyday objects II (I-N.10)</li>
                                        </ul>
                                    </li>
                                    <li><h6>2.G.A.2 Identifies the basic 3-D shapes such as cuboid, cylinder, cone, sphere by their names.</h6>
                                        <ul>
                                            <li>Name the three-dimensional shape (II-O.5)</li>
                                            <li>Select three-dimensional shapes (II-O.6)</li>
                                        </ul>
                                    </li>
                                    <li><h6>2.G.A.3 Traces the 2-D outlines of 3-D objects.</h6>
                                        <ul>
                                            <li>Identify shapes traced from solids (I-N.7)</li>
                                            <li>Identify faces of three-dimensional shapes (I-N.8)</li>
                                        </ul>
                                    </li>
                                    <li><h6>2.G.A.4 Observes and identifies these 2-D shapes.</h6>
                                        <ul>
                                            <li>Name the two-dimensional shape (II-O.1)</li>
                                            <li>Select two-dimensional shapes (II-O.2)</li>
                                            <li>Select three-dimensional shapes (II-O.6)</li>
                                        </ul>
                                    </li>
                                    <li><h6>2.G.A.5 Identifies 2-D shapes viz., rectangle, square, triangle, circle by their names.</h6>
                                        <ul>
                                            <li>Name the two-dimensional shape (II-O.1)</li>
                                            <li>Select two-dimensional shapes (II-O.2)</li>
                                            <li>Select three-dimensional shapes (II-O.6)</li>
                                        </ul>
                                    </li>
                                    <li><h6>2.G.A.6 Describes intuitively the properties of these 2-D shapes.</h6>
                                        <ul>
                                            <li>Symmetry (I-M.7)</li>
                                            <li>Count sides and vertices (II-O.3)</li>
                                            <li>Compare sides and vertices (II-O.4)</li>
                                        </ul>
                                    </li>
                                    <li>2.G.A.7 Identifies and makes straight lines by folding, straight edged objects, stretched strings and draws free hand and with a ruler.</li>
                                    <li>2.G.A.8 Draws horizontal, vertical and slant lines (free hand).</li>
                                    <li>2.G.A.9 Distinguishes between straight and curved lines.</li>
                                    <li>2.G.A.10 Identifies objects by observing their shadows.</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li><h5 style={{color:"red"}}>2.N Numbers</h5>
                        <ul style={{listStyle:"none"}}>
                            <li>2.N.1 Reads and writes numerals for numbers up to ninety-nine.
                                <ul>
                                    <li>Writing numbers up to 100 in words (II-C.3)</li>
                                </ul>
                            </li>
                            <li>2.N.2 Expands a number with respect to place values.
                                <ul>
                                    <li>Place value models - tens and ones (II-J.1)</li>
                                    <li>Value of a digit - tens and ones (II-J.2)</li>
                                    <li>Convert to/from a number - tens and ones (II-J.5)</li>
                                    <li>Convert between place values - tens and ones (II-J.6)</li>
                                </ul>
                            </li>
                            <li>2.N.3 Counts and regroups objects into tens and ones.
                                <ul>
                                    <li>Regroup tens and ones (II-J.3)</li>
                                    <li>Regroup tens and ones - ways to make a number (II-J.4)</li>
                                </ul>
                            </li>
                            <li>2.N.4 Uses the concept of place value in the comparison of numbers.</li>
                            <li>2.N.5 Counts in various ways:
                                <ul>
                                    <li>2.N.5.1 Starting from any number.
                                        <ul>
                                            <li>Counting patterns - up to 100 (II-A.3)</li>
                                            <li>Number lines - up to 100 (II-A.4)</li>
                                            <li>Hundred chart (II-A.5)</li>
                                        </ul>
                                    </li>
                                    <li>2.N.5.2 Group counting etc.
                                        <ul>
                                            <li>Skip-counting (II-A.1)</li>
                                            <li>Skip-counting sequences (II-A.2)</li>
                                            <li>Skip-counting stories (II-A.11)</li>
                                            <li>Skip-counting puzzles (II-A.12)</li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li>2.N.6 Arranges numbers up to hundred in ascending and descending order.
                                <ul>
                                    <li>Comparing numbers up to 100 (II-B.1)</li>
                                    <li>Put numbers up to 100 in order (II-B.2)</li>
                                    <li>Greatest and least - word problems - up to 100 (II-B.3)</li>
                                </ul>
                            </li>
                            <li>2.N.7 Forms the greatest and the smallest two-digit numbers with and without repetition of given digits.
                                <ul>
                                    <li>Largest/smallest number possible (III-U.2)</li>
                                </ul>
                            </li>
                            <li>2.N.8 Indicates and identifies the position of an object in a line.
                                <ul>
                                    <li>Left, middle and right (I-O.3)</li>
                                    <li>Top, middle and bottom (I-O.4)</li>
                                    <li>Location in a grid (I-O.5)</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>2.N.A Addition and Subtraction
                        <ul style={{listStyle:"none"}}>
                            <li>2.N.A.1 Adds and subtracts two digit numbers by drawing representations of tens and ones without and with regrouping.
                                <ul>
                                    <li>Add doubles (II-E.4)</li>
                                    <li>Addition input/output tables - sums to 20 (II-E.8)</li>
                                    <li>Complete the addition sentence - one digit (II-E.11)</li>
                                    <li>Write the addition sentence - one digit (II-E.12)</li>
                                    <li>Balance addition equations - one digit (II-E.13)</li>
                                    <li>Add three or more one-digit numbers (II-E.14)</li>
                                    <li>Add three or more one-digit numbers - word problems (II-E.15)</li>
                                    <li>Subtract doubles (II-F.4)</li>
                                    <li>Subtract a one-digit number from a two-digit number up to 18 (II-F.5)</li>
                                    <li>Subtraction input/output tables - up to 18 (II-F.8)</li>
                                    <li>Complete the subtraction sentence - up to 18 (II-F.11)</li>
                                    <li>Write the subtraction sentence - up to 18 (II-F.12)</li>
                                    <li>Balance subtraction equations - up to 18 (II-F.13)</li>
                                    <li>Add multiples of 10 (II-G.1)</li>
                                    <li>Add a two-digit and a one-digit number - without regrouping (II-G.2)</li>
                                    <li>Add a two-digit and a one-digit number - with regrouping (II-G.3)</li>
                                    <li>Add two two-digit numbers - without regrouping (II-G.4)</li>
                                    <li>Add two two-digit numbers - with regrouping (II-G.5)</li>
                                    <li>Write addition sentences to describe pictures (II-G.6)</li>
                                    <li>Addition input/output tables - up to two digits (II-G.7)</li>
                                    <li>Ways to make a number using addition (II-G.8)</li>
                                    <li>Complete the addition sentence - up to two digits (II-G.10)</li>
                                    <li>Write the addition sentence - up to two digits (II-G.11)</li>
                                    <li>Balance addition equations - up to two digits (II-G.12)</li>
                                    <li>Add three or more numbers up to two digits each (II-G.13)</li>
                                    <li>Add three or more numbers up to two digits - word problems (II-G.14)</li>
                                    <li>Subtract multiples of 10 (II-H.1)</li>
                                    <li>Subtract a one-digit number from a two-digit number - without regrouping (II-H.2)</li>
                                    <li>Subtract a one-digit number from a two-digit number - with regrouping (II-H.3)</li>
                                    <li>Subtract two two-digit numbers - without regrouping (II-H.4)</li>
                                    <li>Subtract two two-digit numbers - with regrouping (II-H.5)</li>
                                    <li>Subtraction input/output tables - up to two digits (II-H.7)</li>
                                    <li>Ways to make a number using subtraction (II-H.8)</li>
                                    <li>Complete the subtraction sentence - up to two digits (II-H.10)</li>
                                    <li>Write the subtraction sentence - up to two digits (II-H.11)</li>
                                    <li>Balance subtraction equations - up to two digits (II-H.12)</li>
                                </ul>
                            </li>
                            <li>2.N.A.2 Adds zero to a number and subtracts zero from a number.
                                <ul>
                                    <li>Make a number with addition - sums to 20 (II-E.9)</li>
                                </ul>
                            </li>
                            <li>2.N.A.3 Constructs addition facts up to 9 + 9 and subtraction facts up to 18 - 9 by using concrete objects.
                                <ul>
                                    <li>Addition facts - sums to 10 (II-D.1)</li>
                                    <li>Addition sentences - sums to 10 (II-D.2)</li>
                                    <li>Addition word problems - sums to 10 (II-D.3)</li>
                                    <li>Addition word problems - sums to 18 (II-D.5)</li>
                                    <li>Subtraction facts - up to 10 (II-D.6)</li>
                                    <li>Subtraction sentences - up to 10 (II-D.7)</li>
                                    <li>Subtraction word problems - up to 10 (II-D.8)</li>
                                    <li>Subtraction word problems - up to 18 (II-D.9)</li>
                                </ul>
                            </li>
                            <li>2.N.A.4 Adds and subtracts single-digit numbers mentally.</li>
                            <li>2.N.A.5 Represents an amount by using different combinations of two or three coins.</li>
                            <li>2.N.A.6 Solves addition, subtraction problems presented through pictures and verbal description.</li>
                            <li>2.N.A.7 Estimates the result of addition and subtraction and compares the result with another given number.</li>
                        </ul>
                    </li>
                    <li>2.D Data Handling
                        <ul style={{listStyle:"none"}}>
                            <li>2.D.1 Collects data through measurement.</li>
                            <li>2.D.2 Records data using tally marks, numbers and pictographs.</li>
                            <li>2.D.3 Draws inferences from the data at the appropriate level.</li>
                        </ul>
                    </li>
                </ul>
            </div>

        </div>
    )
}

export default Class5
