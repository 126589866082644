import './labPricing.css';

export function LabPricing() {
    return (
        <div className="labPricing">
            <h1>Pricing</h1>
            <div className='labPricing_labPricing'>

                <div className='labPricing_left'>
                    <h3>School wise Price</h3>
                    <h3>₹ 2,999/ per student (year)</h3>
                    <h4>Key Benefits</h4>
                    <ul>
                        <li>Access for all students at one school</li>
                        <li>Access for all teachers at one school</li>
                        <li>Comprehensive STEAM curriculum</li>
                        <li>Bluetooth-enabled blocks that support hands-on learning for grades K-8</li>
                        <li>All hardware required at no additional cost</li>
                        <li>3 immersive coding platforms in one application</li>
                        <li>Asynchronous teacher training modules</li>
                        <li>Standards-aligned lesson packs</li>
                        <li>Starter lessons</li>
                        <li>Free online onboarding and training</li>
                        <li>Free warranty on all hardware</li>
                        <li>Additional hardware provided at no cost for each year subscription is renewed</li>
                    </ul>
                </div>

                <div className='labPricing_right'>
                    <h3>Request a Custom Quote</h3>
                    <img src="https://img.freepik.com/free-vector/stem-education-logo-with-scientist-kids-galaxy-theme_1308-66095.jpg" alt="image" />
                    <form>
                        <dl>
                            <dt>First name</dt>
                            <dd><input type="text" /></dd>
                            <dt>Last name</dt>
                            <dd><input type="text" /></dd>
                            <dt>School Email</dt>
                            <dd><input type="email" /></dd>
                            <dt>Job function</dt>
                            <dd>
                                <select>
                                    <option>STEM Teacher</option>
                                    <option>HR</option>
                                    <option>Management</option>
                                </select>
                            </dd>
                            <dt>Country</dt>
                            <dd>
                                <select>
                                    <option>India</option>
                                    <option>US</option>
                                    <option>UK</option>
                                </select>
                            </dd>
                            <dt>Tell us what you are looking for!</dt>
                            <dd>
                                <textarea />
                            </dd>
                        </dl>
                        <button>Submit</button>
                    </form>
                </div>
            </div>
        </div>
    )
}