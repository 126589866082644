import React from 'react'
import Header_ixl from '../Header_IXL/header_ixl'
import '../Class-1/class-1.css';

function Class12() {
    return (
        <div className='class_1'>
            <div className="container">

                <Header_ixl />

                <div className="main-content">
                    <div className="left-content">
                        <h3>India</h3>
                        <h4>Skills available for India class I maths curriculum</h4>
                        <div className="description">
                            Objectives are in black and IXL maths skills are in dark green. Hold your mouse over the name of a skill to view a sample question. Click on the name of a skill to practise that skill.
                        </div>
                    </div>

                    <div className="right-content">
                        <img
                            src="https://upload.wikimedia.org/wikipedia/en/4/41/Flag_of_India.svg"
                            alt="India Flag"
                            className="india-flag"
                        />
                    </div>
                </div>

                <div style={{ display: "flex", width: "100%", marginTop: "20px", padding: "15px" }}>
                    <div style={{ width: "75%", background: "#f65314", padding: "20px" }}>
                        <h6>Showing alignment for:</h6>
                        <p>National Council of Education Research and Training Syllabus</p>
                    </div>
                    <div style={{ width: "25%", background: "gray", padding: "20px" }}>
                        <h6>Actions</h6>
                        <p>Print curriculum</p>
                    </div>
                </div>
            </div>

            <div style={{ marginLeft: "110px" }}>
                <h3 style={{ color: "red" }}>1.G Geometry</h3>
                <ul style={{ listStyle: "none" }}>
                    <li><h6>1.G.A Shapes & Spatial Understanding</h6>
                        <ul style={{ listStyle: "none" }}>
                            <li><h6>1.G.A.1 Develops and uses vocabulary of spatial relationship (Top, Bottom, On, Under, Inside, Outside, Above, Below, Near, Far, Before, After)</h6>
                                <ul>
                                    <li>Inside and outside (U-G.1)</li>
                                    <li>Above and below (I-O.1)</li>
                                    <li>Beside and next to (I-O.2)</li>
                                    <li>Left, middle and right (I-O.3)</li>
                                    <li>Top, middle and bottom (I-O.4)</li>
                                    <li>Location in a grid (I-O.5)</li>
                                </ul>
                            </li>
                        </ul>
                    </li>

                    <li><h6>1.G.B Solids Around Us</h6>
                        <ul style={{ listStyle: "none" }}>
                            <li><h6>1.G.B.1 Collects objects from the surroundings having different sizes and shapes like pebbles, boxes, balls, cones, pipes, etc.</h6></li>
                            <li><h6>1.G.B.2 Sorts, Classifies and describes the objects on the basis of shapes, and other observable properties.</h6>
                                <ul>
                                    <li>Inside and outside (U-G.1)</li>
                                    <li>Above and below (I-O.1)</li>
                                    <li>Beside and next to (I-O.2)</li>
                                    <li>Left, middle and right (I-O.3)</li>
                                    <li>Top, middle and bottom (I-O.4)</li>
                                    <li>Location in a grid (I-O.5)</li>
                                    <li>Inside and outside (U-G.1)</li>
                                    <li>Above and below (I-O.1)</li>
                                    <li>Beside and next to (I-O.2)</li>
                                    <li>Left, middle and right (I-O.3)</li>
                                    <li>Top, middle and bottom (I-O.4)</li>
                                    <li>Location in a grid (I-O.5)</li>
                                    <li>Inside and outside (U-G.1)</li>
                                    <li>Above and below (I-O.1)</li>
                                    <li>Beside and next to (I-O.2)</li>
                                    <li>Left, middle and right (I-O.3)</li>
                                    <li>Top, middle and bottom (I-O.4)</li>
                                    <li>Location in a grid (I-O.5)</li>
                                    <li>Top, middle and bottom (I-O.4)</li>
                                    <li>Location in a grid (I-O.5)</li>
                                </ul>
                            </li>

                            <li style={{ marginTop: "20px" }}><h6>1.G.B.3 Observes and describes the way shapes affect movements like rolling and sliding.</h6></li>
                            <li><h6>1.G.B.4 Sorts 2-D shapes such as flat objects made of card etc.</h6>
                                <ul>
                                    <li>Name the two-dimensional shape (U-Q.1)</li>
                                    <li>Circles (U-Q.2)</li>
                                    <li>Beside and next to (I-O.2)</li>
                                    <li>Squares and rectangles (U-Q.4)</li>
                                    <li>Top, middle and bottom (I-O.4)</li>
                                    <li>Hexagons (U-Q.5)</li>
                                    <li>Name the two-dimensional shape (I-M.1))</li>
                                    <li>Count sides and vertices (I-M.3)</li>
                                    <li>Compare sides and vertices (I-M.4)</li>
                                    <li>Identify shapes traced from solids (I-N.7)</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>

            <div style={{ marginLeft: "110px" }}>
                <h3 style={{ color: "red" }}>1.N Numbers</h3>
                <ul style={{ listStyle: "none" }}>
                    <li>1.N.A Developing a Sense of Numberness, Counting and Operations of Numbers 1 - 9 and Zero
                        <ul>
                            <li>1.N.A.1 Observes object and makes collections of objects.
                                <ul>
                                    <li>Count to 3 (U-A.2)</li>
                                    <li>Count using stickers - up to 3 (U-A.3)</li>
                                    <li>Represent numbers - up to 3 (U-A.6)</li>
                                    <li>Count to 5 (U-B.2)</li>
                                    <li>Count using stickers - up to 5 (U-B.3)</li>
                                    <li>Represent numbers - up to 5 (U-B.6)</li>
                                    <li>One more - up to 5 (U-B.7)</li>
                                    <li>One less - up to 5 (U-B.9)</li>
                                    <li>Count to 10 (U-C.2)</li>
                                    <li>Count using stickers - up to 10 (U-C.3)</li>
                                    <li>Represent numbers - up to 10 (U-C.6)</li>
                                    <li>One more and one less - up to 10 (U-C.11)</li>
                                    <li>Count up and down - up to 10 (U-C.13)</li>
                                    <li>Tally marks - up to 10 (U-C.15)</li>
                                    <li>Number lines - up to 10 (U-C.16)</li>
                                    <li>Before, after and between - up to 10 (U-C.17)</li>
                                    <li>Count forward and backward - up to 10 (U-C.18)</li>
                                    <li>Names of numbers - up to 10 (U-C.19)</li>
                                    <li>Complete a sequence - up to 10 (U-C.20)</li>
                                    <li>Counting review - up to 10 (I-A.1)</li>
                                </ul>
                            </li>
                            <li>1.N.A.2 Arranges the collection of objects in order by
                                <ul>
                                    <li>1.N.A.2.a Matching and
                                        <ul>
                                            <li>Select two-dimensional shapes (I-M.2)</li>
                                            <li>Select three-dimensional shapes (I-N.4)</li>
                                        </ul>
                                    </li>
                                    <li>1.N.A.2.b One to one correspondence</li>
                                </ul>
                            </li>
                            <li>1.N.A.3 Counts the number of objects in a collection.
                                <ul>
                                    <li>Counting review - up to 10 (I-A.1)</li>
                                    <li>Counting review - up to 20 (I-A.3)</li>
                                    <li>Counting tens and ones - up to 30 (I-A.4)</li>
                                    <li>Count on ten frames - up to 40 (I-A.5)</li>
                                    <li>Counting - up to 100 (I-A.6)</li>
                                    <li>Counting tens and ones - up to 99 (I-A.7)</li>
                                    <li>Counting by twos, fives and tens with pictures (I-A.8)</li>
                                </ul>
                            </li>
                            <li>1.N.A.4 Makes collection of objects corresponding to a specific number.</li>
                            <li>1.N.A.5 Recognises and speaks numbers from 1 to 9.
                                <ul>
                                    <li>Counting review - up to 10 (I-A.1)</li>
                                </ul>
                            </li>
                            <li>1.N.A.6 Uses numbers from 1 to 9 in counting and comparison. (Real objects and repeated events like clapping to be used for counting)
                                <ul>
                                    <li>Comparing numbers up to 10 (I-K.2)</li>
                                </ul>
                            </li>
                            <li>1.N.A.7 Reads and writes numerals from 1 to 9.
                                <ul>
                                    <li>Writing numbers in words (I-A.21)</li>
                                </ul>
                            </li>
                            <li>1.N.A.8 Adds and subtracts using real objects and pictures.
                                <ul>
                                    <li>Add with pictures - sums up to 10 (I-C.1)</li>
                                    <li>Addition sentences - sums up to 10 (I-C.2)</li>
                                    <li>Subtract with pictures - numbers up to 10 (I-G.1)</li>
                                    <li>Subtraction sentences - numbers up to 10 (I-G.2)</li>
                                </ul>
                            </li>
                            <li>1.N.A.9 Adds and subtracts the numbers using symbols '+' and '-'.
                                <ul>
                                    <li>Addition sentences using number lines - sums up to 10 (I-C.3)</li>
                                    <li>Adding zero (I-C.4)</li>
                                    <li>Adding 1 (I-D.1)</li>
                                    <li>Adding 2 (I-D.2)</li>
                                    <li>Adding 3 (I-D.3)</li>
                                    <li>Adding 4 (I-D.4)</li>
                                    <li>Adding 5 (I-D.5)</li>
                                    <li>Adding 6 (I-D.6)</li>
                                    <li>Adding 7 (I-D.7)</li>
                                    <li>Adding 8 (I-D.8)</li>
                                    <li>Adding 9 (I-D.9)</li>
                                    <li>Adding 0 (I-D.10)</li>
                                    <li>Addition facts - sums up to 10 (I-E.1)</li>
                                    <li>Ways to make a number - addition sentences (I-E.2)</li>
                                    <li>Make a number using addition - sums up to 10 (I-E.3)</li>
                                    <li>Complete the addition sentence - sums up to 10 (I-E.4)</li>
                                    <li>Addition word problems - sums up to 10 (I-E.5)</li>
                                    <li>Addition sentences for word problems - sums up to 10 (I-E.6)</li>
                                    <li>Addition facts - sums up to 18 (I-E.7)</li>
                                    <li>Addition word problems - sums up to 18 (I-E.9)</li>
                                    <li>Addition facts - sums up to 20 (I-E.11)</li>
                                    <li>Related addition facts (I-E.14)</li>
                                    <li>Addition sentences: true or false? (I-E.15)</li>
                                    <li>Add doubles (I-F.1)</li>
                                    <li>Complete the addition sentence - make ten (I-F.5)</li>
                                    <li>Add three numbers - make ten (I-F.6)</li>
                                    <li>Add three numbers (I-F.9)</li>
                                    <li>Add three numbers - word problems (I-F.10)</li>
                                    <li>Subtraction sentences using number lines - numbers up to 10 (I-G.3)</li>
                                    <li>Subtracting 1 (I-H.1)</li>
                                    <li>Subtracting 2 (I-H.2)</li>
                                    <li>Subtracting 3 (I-H.3)</li>
                                    <li>Subtracting 4 (I-H.4)</li>
                                    <li>Subtracting 5 (I-H.5)</li>
                                    <li>Subtracting 6 (I-H.6)</li>
                                    <li>Subtracting 7 (I-H.7)</li>
                                    <li>Subtracting 8 (I-H.8)</li>
                                    <li>Subtracting 9 (I-H.9)</li>
                                    <li>Subtracting 0 (I-H.10)</li>
                                    <li>Subtraction facts - numbers up to 10 (I-I.1)</li>
                                    <li>Ways to make a number - subtraction sentences (I-I.2)</li>
                                    <li>Ways to subtract from a number - subtraction sentences (I-I.3)</li>
                                    <li>Make a number using subtraction - numbers up to 10 (I-I.4)</li>
                                    <li>Complete the subtraction sentence (I-I.5)</li>
                                    <li>Subtraction word problems - numbers up to 10 (I-I.6)</li>
                                    <li>Subtraction sentences for word problems - numbers up to 10 (I-I.7)</li>
                                    <li>Subtraction facts - numbers up to 18 (I-I.8)</li>
                                    <li>Subtraction word problems - numbers up to 18 (I-I.10)</li>
                                    <li>Related subtraction facts (I-I.13)</li>
                                    <li>Subtraction sentences: true or false? (I-I.14)</li>
                                    <li>Subtract doubles (I-J.2)</li>
                                </ul>
                            </li>
                            <li>1.N.A.10 Approaches zero through the subtraction pattern (such as 3 – 1 = 2, 3 – 2 = 1, 3 – 3 = 0).
                                <ul>
                                    <li>Subtracting 1 (I-H.1)</li>
                                    <li>Subtracting 2 (I-H.2)</li>
                                    <li>Subtracting 3 (I-H.3)</li>
                                    <li>Subtracting 0 (I-H.10)</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>

                <ul>
                    <li><h3 style={{ color: "red" }}>1.MNY Money</h3>
                        <ul>
                            <li>1.MNY.1 Identifies common currency notes and coins.
                                <ul>
                                    <li>Coin values (I-R.1)</li>
                                </ul>
                            </li>
                            <li>1.MNY.2 Puts together small amounts of money.
                                <ul>
                                    <li>Count coins (I-R.2)</li>
                                    <li>Count notes (I-R.3)</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li><h3 style={{ color: "red" }}>1.MEA Measurement</h3>
                        <ul>
                            <li>1.MEA.A Length
                                <ul>
                                    <li>1.MEA.A.1 Distinguishes between near, far, thin, thick, longer/taller, shorter, high, low.
                                        <ul>
                                            <li>Left, middle and right (I-O.3)</li>
                                            <li>Top, middle and bottom (I-O.4)</li>
                                            <li>Location in a grid (I-O.5)</li>
                                            <li>Long and short (I-Q.1)</li>
                                            <li>Tall and short (I-Q.2)</li>
                                        </ul>
                                    </li>
                                    <li>1.MEA.A.2 Seriates objects by comparing their length.
                                        <ul>
                                            <li>Long and short (I-Q.1)</li>
                                            <li>Tall and short (I-Q.2)</li>
                                        </ul>
                                    </li>
                                    <li>1.MEA.A.3 Measures short lengths in terms of non-uniform units (in the context of games e.g. 'Gilli Danda' and 'marble games').</li>
                                    <li>1.MEA.A.4 Estimates distance and length, and verifies using non-uniform units (e.g. hand span etc.)</li>
                                </ul>
                            </li>
                            <li>1.MEA.B Weight
                                <ul>
                                    <li>1.MEA.B.1 Compares between heavy and light objects.
                                        <ul>
                                            <li>Light and heavy (I-Q.3)</li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li>1.MEA.C Time
                                <ul>
                                    <li>1.MEA.C.1 Distinguishes between events occurring in time using terms - earlier and later.</li>
                                    <li>1.MEA.C.2 Gets the qualitative feel of long & short duration, of school days vs. holidays.
                                        <ul>
                                            <li>Days of the week (I-V.1)</li>
                                            <li>Seasons of the year (I-V.2)</li>
                                            <li>Read a calendar (I-V.3)</li>
                                            <li>Months of the year (I-V.4)</li>
                                        </ul>
                                    </li>
                                    <li>1.MEA.C.3 Narrates the sequence of events in a day.</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li><h3 style={{ color: "red" }}>1.DH Data Handling</h3>
                        <ul>
                            <li>1.DH.1 Collects, represents and interprets simple data such as measuring the arm length or circumference of the head using a paper strip.
                                <ul>
                                    <li>Which tally chart is correct? (I-P.3)</li>
                                    <li>Interpret tally charts (I-P.4)</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li><h3 style={{ color: "red" }}>1.P Patterns</h3>
                        <ul>
                            <li>1.P.1 Describes sequences of simple patterns found in shapes in the surroundings and in numbers, e.g. stamping activity using fingers and thumb.
                                <ul>
                                    <li>Introduction to patterns (I-S.1)</li>
                                    <li>Make a pattern (I-S.4)</li>
                                </ul>
                            </li>
                            <li>1.P.2 Completes a given sequence of simple patterns found in shapes in the surroundings and in numbers.
                                <ul>
                                    <li>Skip-counting patterns - with tables (I-A.17)</li>
                                    <li>Sequences - count up and down by 1, 2, 3, 5 and 10 (I-A.18)</li>
                                    <li>Sequences - count up and down by 100 (I-A.19)</li>
                                    <li>Find the next shape in a pattern (I-S.2)</li>
                                    <li>Complete a pattern (I-S.3)</li>
                                    <li>Growing patterns (I-S.5)</li>
                                    <li>Find the next shape in a growing pattern (I-S.6)</li>
                                    <li>Find the next row in a growing pattern (I-S.7)</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default Class12









