import React from 'react'
import "./FouthPage.css";

export function FouthPage() {
  return (
    <div className='container-4'>
      {/* <div class="container-1">
        <h2 class="heat-text">Confused on How to Start?</h2>
        <h4 class="sub-head-text">Our experts are here to help you...</h4>
        <button class="button-text orenge-text"> Request a Call</button>
        <img src="https://images.avishkaar.cc/misc/home/request-call-desktop-1.png" alt="" class="contant-img">

    </div> */}

      <h1 className='heat-text'>Confused on How to Start?</h1>
      <h4 className='sub-head-text'>Our experts are here to help you...</h4>
      <button className='button-text orenge-text'> <a href="http://localhost:3000/Contact"></a>Request a Call</button>
      <img src="https://images.avishkaar.cc/misc/home/request-call-desktop-1.png" alt="" class="contant-img" />
    </div>
  )
}
