import React from 'react';
import './ourPlatform.css';
import ServiceSection from './ServiceDetails/serviceSection';
// import { AdvanceConcept } from '../advance-concept/advance-concept';

function OurPlatform() {
  return (
    <div className="stemverse-card">
      
      {/* <div className="top-section">
        <img src="https://media.istockphoto.com/id/1303512675/photo/happy-kids-programming-electric-toys-and-robots-at-robotics-classroom.jpg?s=612x612&w=0&k=20&c=JgSK0-5zo2UVF4ynJvB8PFuGWzNGOOhwKDAr11wcjBA=" alt="Kids Education" className="top-image" />
        <div className="top-text">
          <h1>A New Approach to <span className="highlight">Kids Education</span></h1>
          <p style={{ color: 'black' }}>We provide best solutions for a Clean Environment if you need any help in cleaning or maintenance.</p>
          <div className="top-buttons">
            <button className="btn admission-btn">Admission Now</button>
            <button className="btn classes-btn">Classes</button>
          </div>
        </div>
      </div> */}

      {/* Rest of your Service component */}
      {/* <div className="header">
          <h1>(AI Integrated Education Platform)</h1>
          <h2>STEM WORLD</h2>
          <img src='logo22.png' alt="Laptop displaying code blocks" className="laptop-image" />
        </div> */}
      <div className="platform-logo">
        <img src='logo12.png' alt="StemVerse Logo" />
      </div>
      <div>
        {/* <ServiceSection /> */}
      </div>
      <div className="content">
        <div className="section">
          <h2 style={{ color: 'black',fontStyle:"bold",fontSize:"32px",color:"maroon" }}>STEMVerse</h2>
          <p style={{fontSize:"20px",color:"blue"}}>
            STEMVERSE is a graphical programming software designed to introduce children and beginners to the world of coding, AI, and robotics.
          </p>
        </div>
        <div className="section">
          <h2 style={{fontStyle:"bold",fontSize:"32px",color:"maroon"}}>Features</h2>
          <ul style={{textAlign:"left",fontSize:"18px",color:"blue"}}>
            <li>
              It features a visual interface that allows users to drag and drop code blocks to create programs and extensions related to their class topics.
            </li>
            <li>
              STEMVerse supports various hardware platforms such as Arduino and Scratch-based robots, enabling users to control and interact with physical devices using their K12 concept-based curriculum through extensions.
            </li>
            <li>
              It is an intuitive tool aimed at making learning programming fun, interactive, and accessible for learners of all ages.
            </li>
            <li>
              It also supports Python compiler extensions for AI, ML and Robotics to expand its educational potential, offering learners a rich platform to explore, create, and innovate.
            </li>
          </ul>
        </div>
        <div className="footer">
          <p>
            {/* For a visual experience, please visit & login at <a href="https://www.stemverse.app">www.stemverse.app</a> */}
          </p>
        </div>
      </div>
      {/* <AdvanceConcept /> */}
    </div>
  );

}

export default OurPlatform;
