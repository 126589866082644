// Contact.jsx
import React from "react";
import './contact.css';
import { Helmet } from "react-helmet";
import Mapadd from "../map-add/map-add";
import ContactInfo from "./ContactInfo";
import ContactForm from "./ContactForm";

export function Contact() {
    return (
        <div className="contact_page">
            <Helmet>
                <title>STEM WORLD | Contact</title>
            </Helmet>

            <h1 className="pg heading mt-5">Let's Talk with Stem World</h1>
            <div id="contact_stem" className="contact-container">
                <ContactInfo />
                <ContactForm />
            </div>
            <Mapadd />
        </div>
    );
}
