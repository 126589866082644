import React from 'react';
// import './ai-ml.css';
import { useMediaQuery } from 'react-responsive';

function Aiml() {

    const isSmall = useMediaQuery({ query: '(max-width: 576px)' });
    const isMedium = useMediaQuery({ query: '(min-width: 577px) and (max-width: 768px)' });
    const isLarge = useMediaQuery({ query: '(min-width: 769px) and (max-width: 992px)' });
    const isXLarge = useMediaQuery({ query: '(min-width: 993px) and (max-width: 1200px)' });
    const isXXLarge = useMediaQuery({ query: '(min-width: 1201px)' });

    return (
        <div>

            {isSmall && (
                <div className="container-sm" style={{ marginTop: "300px", marginBottom: "-150px" }}>
                    <div>
                        <div>
                            <img src='many-image16.jpg.png' alt="Lab Setup in Schools" style={{  boxShadow: '3px 5px 6px gray', borderRadius: '15px', margin: '5px' }} />
                        </div>
                        <div>
                            <h1 className='text-center mt-1' style={{ fontFamily: "'Dancing Script', cursive", color: "orange", fontSize: '40px' }}>Lab Setup in Schools</h1>
                            <p style={{ textAlign: "justify" }}>
                                We establish STEM/AI & Robotics Innovation Labs, where education meets innovation at the intersection of robotics and artificial intelligence. Our state-of-the-art facilities are meticulously designed to provide students with an immersive learning environment that sparks curiosity and fosters hands-on exploration. Our expert trainers conduct engaging classes, guiding young minds in transforming their ideas into tangible prototypes. Through this dynamic learning experience, students gain invaluable skills and insights that prepare them for the future. We inspire the next generation of creators and thinkers.                            </p>
                        </div>
                    </div>
                    <div>
                        <div>
                            <h1 className='text-center mt-1' style={{ fontFamily: "'Dancing Script', cursive", color: "orange", fontSize: '40px' }}>Research & Development</h1>
                            <p style={{ textAlign: "justify" }}>
                                Our educational model integrates interdisciplinary approaches and real-world applications, empowering students to apply STEM knowledge in diverse settings. We support research at the intersection of technology and STEM education to advance both theory and methodology. By fostering innovative thinking and practical skills, we equip students to tackle real-world challenges and contribute to the evolving landscape of education and technology.                            </p>
                        </div>
                        <div>
                            <img src='iot12.png' alt="Research & Development" style={{ boxShadow: '3px 5px 6px gray', borderRadius: '15px', margin: '5px' }} />
                        </div>
                    </div>

                </div>
            )}
            {isMedium && (
                <div className="container-md mt-4">
                    <div>
                        <div>
                            <img src='many-image16.jpg.png' alt="Lab Setup in Schools" style={{  boxShadow: '3px 5px 6px gray', borderRadius: '15px', margin: '5px' }} />
                        </div>
                        <div>
                            <h1 className='text-center mt-1' style={{ fontFamily: "'Dancing Script', cursive", color: "orange", fontSize: '40px' }}>Lab Setup in Schools</h1>
                            <p style={{ textAlign: "justify" }}>
                                We establish STEM/AI & Robotics Innovation Labs, where education meets innovation at the intersection of robotics and artificial intelligence. Our state-of-the-art facilities are meticulously designed to provide students with an immersive learning environment that sparks curiosity and fosters hands-on exploration. Our expert trainers conduct engaging classes, guiding young minds in transforming their ideas into tangible prototypes. Through this dynamic learning experience, students gain invaluable skills and insights that prepare them for the future. We inspire the next generation of creators and thinkers.                            </p>
                        </div>
                    </div>
                    <div>
                        <div>
                            <h1 className='text-center mt-1' style={{ fontFamily: "'Dancing Script', cursive", color: "orange", fontSize: '40px' }}>Research & Development</h1>
                            <p style={{ textAlign: "justify" }}>
                                Our educational model integrates interdisciplinary approaches and real-world applications, empowering students to apply STEM knowledge in diverse settings. We support research at the intersection of technology and STEM education to advance both theory and methodology. By fostering innovative thinking and practical skills, we equip students to tackle real-world challenges and contribute to the evolving landscape of education and technology.                            </p>
                        </div>
                        <div>
                            <img src='iot12.png' alt="Research & Development" style={{  boxShadow: '3px 5px 6px gray', borderRadius: '15px', margin: '5px' }} />
                        </div>
                    </div>

                </div>
            )}
            {isLarge && (
                <div className="container-lg">
                    <div>
                        <div>
                            <img src='many-image16.jpg.png' alt="Lab Setup in Schools" style={{ width: "500px", boxShadow: '3px 5px 6px gray', borderRadius: '15px', margin: '5px' }} />
                        </div>
                        <div>
                            <h1 className='text-center mt-1' style={{ fontFamily: "'Dancing Script', cursive", color: "orange", fontSize: '40px' }}>Lab Setup in Schools</h1>
                            <p style={{ textAlign: "justify" }}>
                                We establish STEM/AI & Robotics Innovation Labs, where education meets innovation at the intersection of robotics and artificial intelligence. Our state-of-the-art facilities are meticulously designed to provide students with an immersive learning environment that sparks curiosity and fosters hands-on exploration. Our expert trainers conduct engaging classes, guiding young minds in transforming their ideas into tangible prototypes. Through this dynamic learning experience, students gain invaluable skills and insights that prepare them for the future. We inspire the next generation of creators and thinkers.                            </p>
                        </div>
                    </div>
                    <div>
                        <div>
                            <h1 className='text-center mt-1' style={{ fontFamily: "'Dancing Script', cursive", color: "orange", fontSize: '40px' }}>Research & Development</h1>
                            <p style={{ textAlign: "justify" }}>
                                Our educational model integrates interdisciplinary approaches and real-world applications, empowering students to apply STEM knowledge in diverse settings. We support research at the intersection of technology and STEM education to advance both theory and methodology. By fostering innovative thinking and practical skills, we equip students to tackle real-world challenges and contribute to the evolving landscape of education and technology.                            </p>
                        </div>
                        <div>
                            <img src='iot12.png' alt="Research & Development" style={{ width: "500px", boxShadow: '3px 5px 6px gray', borderRadius: '15px', margin: '5px' }} />
                        </div>
                    </div>

                </div>
            )}
            {isXLarge && (
                <div className="container-xl">
                    <div className='m-3 d-flex w-100'>
                        <div>
                            <img src='many-image16.jpg.png' alt="Lab Setup in Schools" style={{ width: "500px", boxShadow: '3px 5px 6px gray', borderRadius: '15px', margin: '5px' }} />
                        </div>
                        <div className='ms-4'>
                            <h1 className='text-center mt-1' style={{ fontFamily: "'Dancing Script', cursive", color: "orange", fontSize: '40px' }}>Lab Setup in Schools</h1>
                            <p style={{ textAlign: "justify" }}>
                                We establish STEM/AI & Robotics Innovation Labs, where education meets innovation at the intersection of robotics and artificial intelligence. Our state-of-the-art facilities are meticulously designed to provide students with an immersive learning environment that sparks curiosity and fosters hands-on exploration. Our expert trainers conduct engaging classes, guiding young minds in transforming their ideas into tangible prototypes. Through this dynamic learning experience, students gain invaluable skills and insights that prepare them for the future. We inspire the next generation of creators and thinkers.                            </p>
                        </div>
                    </div>

                    <div className='m-3 d-flex w-100 mt-4'>
                        <div className='me-3'>
                            <h1 className='text-center mt-1' style={{ fontFamily: "'Dancing Script', cursive", color: "orange", fontSize: '40px' }}>Research & Development</h1>
                            <p style={{ textAlign: "justify" }}>
                                Our educational model integrates interdisciplinary approaches and real-world applications, empowering students to apply STEM knowledge in diverse settings. We support research at the intersection of technology and STEM education to advance both theory and methodology. By fostering innovative thinking and practical skills, we equip students to tackle real-world challenges and contribute to the evolving landscape of education and technology.                            </p>
                        </div>
                        <div>
                            <img src='iot12.png' alt="Research & Development" style={{ width: "500px", boxShadow: '3px 5px 6px gray', borderRadius: '15px', margin: '5px' }} />
                        </div>
                    </div>


                </div>
            )}
            {isXXLarge && (
                <div className="container-xxl">
                    <div className='m-3 d-flex w-100'>
                        <div>
                            <img src='many-image16.jpg.png' alt="Lab Setup in Schools" style={{ width: "500px", boxShadow: '3px 5px 6px gray', borderRadius: '15px', margin: '5px', marginTop: '20px' }} />
                        </div>
                        <div className='ms-4'>
                            <h1 className='text-center mt-1' style={{ fontFamily: "'Dancing Script', cursive", color: "orange", fontSize: '40px' }}>Lab Setup in Schools</h1>
                            <p style={{ textAlign: "justify", width: '591px', marginLeft: '89px' }}>
                                We establish STEM/AI & Robotics Innovation Labs, where education meets innovation at the intersection of robotics and artificial intelligence. Our state-of-the-art facilities are meticulously designed to provide students with an immersive learning environment that sparks curiosity and fosters hands-on exploration. Our expert trainers conduct engaging classes, guiding young minds in transforming their ideas into tangible prototypes. Through this dynamic learning experience, students gain invaluable skills and insights that prepare them for the future. We inspire the next generation of creators and thinkers.                            </p>
                        </div>
                    </div>

                    <div className='m-3 d-flex w-100 mt-4'>
                        <div className='me-3'>
                            <h1 className='text-center mt-1' style={{ fontFamily: "'Dancing Script', cursive", color: "orange", fontSize: '40px' }}>Research & Development</h1>
                            <p style={{ textAlign: "justify", width: '629px' }}>
                                Our educational model integrates interdisciplinary approaches and real-world applications, empowering students to apply STEM knowledge in diverse settings. We support research at the intersection of technology and STEM education to advance both theory and methodology. By fostering innovative thinking and practical skills, we equip students to tackle real-world challenges and contribute to the evolving landscape of education and technology.                            </p>
                        </div>
                        <div>
                            <img src='iot12.png' alt="Research & Development" style={{ width: "500px", boxShadow: '3px 5px 6px gray', borderRadius: '15px', marginLeft: '79px' }} />
                        </div>
                    </div>


                </div>
            )}



        </div>
    )
}

export default Aiml;